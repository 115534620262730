import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { FaRegWindowClose} from "react-icons/fa";
import '../Admin/Admin.css';
import Multiselect from 'multiselect-react-dropdown';


export class AddDmcUsersModal extends Component {
    constructor(props) {
        super(props);
        this.state = { UserRoles: [], AdpAllEmp: [], UserName: '', UserEmail: '', UserID: '', HomeCode2: '', Msg: '', ErrorMsg: '', filteredSuggestions: [], isActive: false};
        this.handleSubmit = this.handleSubmit.bind(this);
       

  
    }
     
    componentDidMount() {
        fetch('CommonDropDownLists/GetAllUserRole')
            .then(response => response.json())
            .then(data => {
                this.setState({ UserRoles: data, Msg: '', ErrorMsg:'' });
            });

        fetch('AdpEmployeeList/GetAdpEmpList')
            .then(response => response.json())
            .then(data => {
                this.setState({ AdpAllEmp: data, Msg: '', ErrorMsg: '' });
            });
    }

    handleSubmit(event) {
        if (event.target.dmc_EmpID.value != "" && event.target.dmc_UserEmail.value != "") {
            this.setState({ isActive: true, ErrorMsg:'' });
            event.stopPropagation();
            event.preventDefault();
            var ArrUserRoleNames = event.target.dmcUserRoleId.selectedOptions;
            var UserRoleName = "";
            for (const Role of ArrUserRoleNames) {
                if (UserRoleName == "") {
                    UserRoleName = Role.text;
                }
                else {
                    UserRoleName = UserRoleName + "," + Role.text;
                }

            }
            fetch('Admin/AddUpdateUser', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    DmcUserName: "" + event.target.dmc_UserName.value + "",
                    DmcUserEmail: "" + event.target.dmc_UserEmail.value + "",
                    DmcEmpID: "" + event.target.dmc_EmpID.value + "",
                    HomeCode2: "" + event.target.homeCode2.value + "",
                    DmcUserRoleNames: "" + UserRoleName + "",
                    IsAdmin: event.target.dmcIsAdmin.checked
                })
            })
                .then(res => res.json())
                .then((result) => {
                    localStorage.setItem('UpdateDrawing', "yes")
                    var message
                    if (result == "User already exists") {
                        this.setState({
                            ErrorMsg: result
                        });
                    }
                    else {
                        this.setState({
                            Msg: result
                        });
                    }
                    this.setState({
                        filteredSuggestions: [],
                        UserName: '',
                        UserEmail: '',
                        UserID: '',
                        isActive: false
                    });
                    setTimeout(() => {
                        this.setState({
                            Msg: '', ErrorMsg:''
                        });
                        document.getElementById("CloseModel").click();
                    }, 1500)
                    // alert(result);
                },
                    (error) => {
                        alert('Failed');
                    })
        }
        else {
            this.setState({
                ErrorMsg: "UserID & UserEmail can't be blank"
            });
            event.stopPropagation();
            event.preventDefault();
        }
    }
  

    render() {
        const onChangeHandler = (UserName) => {
            let filteredSuggestions = []
            if (UserName.length > 0) {
                filteredSuggestions = this.state.AdpAllEmp.filter(usr => {
                    return usr.empName.toLowerCase().indexOf(UserName.toLowerCase()) > -1
                });
            }
            this.setState({
                filteredSuggestions: filteredSuggestions,
                UserName: UserName,
                ErrorMsg: '',
                 Msg: ''
            });
        }
        const onSuggestionHandler = (UserName, UserEmail, UserID, HomeCode2) => {
            this.setState({
                filteredSuggestions: [],
                UserName: UserName,
                UserEmail: UserEmail,
                UserID: UserID,
                HomeCode2: HomeCode2,
                Msg: '',
                ErrorMsg:''
            });
        }
        const handleDivBlur = event => {
            this.setState({
                filteredSuggestions: [],
                UserName: '',
                UserEmail: '',
                UserID: '',
                Msg: '',
                ErrorMsg:''
            });
            document.getElementById("CloseModel").click();
        };
        const onChangeEmailHandler = (UserEmail) => {
            this.setState({
                UserEmail: UserEmail,
                ErrorMsg:''
            });
        }
        
        {

        }
      
        
        return (
            <div className="container">

                <Modal backdrop="static"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className={this.state.isActive == true ? 'loading' : ''}>
                    <Modal.Header style={{ backgroundColor: "rgba(0, 0, 0, 0.05)"}} clooseButton>
                        <div style={{ width: "50%" }}> <Modal.Title id="contained-modal-title-vcenter">
                            Add User
                        </Modal.Title></div>
                        <div style={{ width: "50%", textAlign: "right" }}><span onClick={handleDivBlur}><FaRegWindowClose style={{ width: "1.5em", height: "1.5em" }} /></span>
                            <span id="CloseModel" style={{display:"none" }} onClick={this.props.onHide}></span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={12} >
                                <Form onSubmit={this.handleSubmit} >
                                    <Form.Group controlId="dmc_UserName" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>UserName</Form.Label>
                                        <Form.Control  type="text" name="dmc_UserName" required
                                            placeholder="UserName" tooltip="" onChange={e => onChangeHandler(e.target.value)} value={this.state.UserName}  />
                                            </Form.Group>
                                    <div className="AutoCompletediv">
                                    {this.state.filteredSuggestions && this.state.filteredSuggestions.map((Suggestions, i) =>
                                        <div key={i} className="AutoComplete" onClick={() => onSuggestionHandler(Suggestions.empName, Suggestions.emailAddress, Suggestions.positionID, Suggestions.departmentName)}> {Suggestions.empName} </div>
                                        )}
                                    </div>
                                    <Form.Group controlId="dmc_EmpID" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>UserID</Form.Label>
                                            <Form.Control type="text" name="dmc_EmpID" required={true}
                                            placeholder="UserID" value={this.state.UserID} disabled />
                                    </Form.Group>
                                    <Form.Group controlId="dmc_UserEmail" style={{ display: "flex", paddingBottom: "15px"  }}>
                                        <Form.Label >UserEmail</Form.Label>
                                        <Form.Control  type="text" name="dmc_UserEmail" 
                                                placeholder="UserEmail" onChange={e => onChangeEmailHandler(e.target.value)} value={this.state.UserEmail}  />
                                    </Form.Group>
                                    
                                   
                                    <Form.Group controlId="homeCode2" style={{ display: "flex", paddingBottom: "15px"  }}>
                                        <Form.Label>Department</Form.Label>
                                        <Form.Control type="text" name="homeCode2"
                                                placeholder="Department" value={this.state.HomeCode2} disabled />
                                    </Form.Group>
                                    <Form.Group controlId="dmcUserRoleId" style={{ display: "flex",paddingBottom :"15px" }}>
                                        <Form.Label>User Roles</Form.Label>
                                        <Form.Select as="select" required multiple>
                                            <option disabled value={''} >{'Select Roles (use ctrl for multiple selection)'}</option>
                                            {this.state.UserRoles.map(UserRole =>
                                                <option value={UserRole.dmcUserRoleId}>{UserRole.roleName}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group controlId="dmcIsAdmin" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label style={{ width: "23%", marginTop:"-2px" }}>Is Admin</Form.Label>
                                        <Form.Check
                                            name="dmcIsAdmin"
                                            type="checkbox"
                                         
                                        />
                                    </Form.Group>
                                    <Form.Group >
                                        <div style={{ width: "100%",textAlign:"center" }} >
                                            <Button style={{ backgroundColor: "black", color: "white" }} type="submit">
                                                Add User
                                            </Button>
                                        </div>
                                    </Form.Group>
                                    <Form.Group >
                                        <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }} >
                                                <span className="Msg" >{this.state.Msg}</span>
                                                <span className="ErrorMsg" >{this.state.ErrorMsg}</span>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                  </div>
                </Modal>
            </div>
        )
    }

}
