import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { FaRegWindowClose } from "react-icons/fa";
import '../Admin/Admin.css';



export class EditDmcUsersModal extends Component {

    constructor(props) {
        super(props);
        this.state = { UserRoles: [], AdpAllEmp: [], UserName: '', UserEmail: '', UserID: '', HomeCode2: '', Msg: '', ErrorMsg:'', Change: 0, filteredSuggestions: [], isActive: false };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        //alert(props.location.state.dmcusername);
            fetch('CommonDropDownLists/GetAllUserRole')
                .then(response => response.json())
                .then(data => {
                    this.setState({ UserRoles: data, ErrorMsg:'' });
                });

            fetch('AdpEmployeeList/GetAdpEmpList')
                .then(response => response.json())
                .then(data => {
                    this.setState({ AdpAllEmp: data, ErrorMsg:'' });
                });
    }


    handleSubmit(event) {
        if (event.target.dmcempid.value != "" && event.target.dmcuseremail.value != "") {
            this.setState({ isActive: true, });
            event.stopPropagation();
            event.preventDefault();

            var ArrUserRoleNames = event.target.dmcUserRoleId.selectedOptions;
            var UserRoleName = "";
            for (const Role of ArrUserRoleNames) {
                if (UserRoleName == "") {
                    UserRoleName = Role.text;
                }
                else {
                    UserRoleName = UserRoleName + "," + Role.text;
                }

            }

            fetch('Admin/AddUpdateUser', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },

                body: JSON.stringify({
                    DmcUserID: parseInt(this.props.dmcuserid),
                    DmcUserName: "" + event.target.dmcusername.value + "",
                    DmcUserEmail: "" + event.target.dmcuseremail.value + "",
                    DmcEmpID: "" + event.target.dmcempid.value + "",
                    HomeCode2: "" + event.target.homecode2.value + "",
                    DmcUserRoleNames: "" + UserRoleName + "",
                    IsAdmin: event.target.dmcIsAdmin.checked

                })
            })
                .then(res => res.json())
                .then((result) => {
                    localStorage.setItem('UpdateDrawing', "yes")
                    if (result == "User already exists") {
                        this.setState({
                            ErrorMsg: result
                        });
                    }
                    else {
                        this.setState({
                            Msg: result
                        });
                    }
                    this.setState({
                        filteredSuggestions: [],
                        isActive: false
                    });
                    setTimeout(() => {
                        this.setState({
                            Msg: '',
                            ErrorMsg:''
                        });
                        document.getElementById("CloseModel").click();
                    }, 1500)
                },
                    (error) => {
                        alert('Failed');
                    })
        }
        else {
            this.setState({
                ErrorMsg: "UserID & UserEmail can't be blank"
            });
            event.stopPropagation();
            event.preventDefault();
        }
    }

    render() {
        const onChangeHandler = (UserName) => {
            let filteredSuggestions = []
            if (UserName.length > 0) {
                filteredSuggestions = this.state.AdpAllEmp.filter(usr => {
                    return usr.empName.toLowerCase().indexOf(UserName.toLowerCase()) > -1
                });
            }

            this.setState({
                filteredSuggestions: filteredSuggestions,
                Change: 1,
                UserName: UserName
            });
        }
        const onSuggestionHandler = (UserName, UserEmail, UserID, HomeCode2) => {
            this.setState({
                filteredSuggestions: [],
                UserName: UserName,
                UserEmail: UserEmail,
                UserID: UserID,
                HomeCode2: HomeCode2,
                Msg: '',
                ErrorMsg:'',
                Change:1
            });
        }
        const handleDivBlur = event => {
            this.setState({
                filteredSuggestions: [],
                UserName: '',
                UserEmail: '',
                UserID: '',
                Msg: '',
                ErrorMsg:'',
                HomeCode2:'',
                Change:0
            });
            document.getElementById("CloseModel").click();
        };
        return (
            <div className="container">
                <Modal backdrop="static"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className={this.state.isActive == true ? 'loading' : ''}>
                    <Modal.Header style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }} clooseButton>
                        <div style={{ width: "50%" }}> <Modal.Title id="contained-modal-title-vcenter">
                            Edit User
                        </Modal.Title></div>
                        <div style={{ width: "50%", textAlign: "right" }}><span onClick={handleDivBlur}><FaRegWindowClose style={{ width: "1.5em", height: "1.5em" }} /></span>
                            <span id="CloseModel" style={{ display: "none" }} onClick={this.props.onHide}></span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={12}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="dmcusername" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>UserName</Form.Label>
                                        <Form.Control type="text" name="dmcusername" required
                                            placeholder="UserName" tooltip="" onChange={e => onChangeHandler(e.target.value)} value={this.state.UserName == "" && this.state.Change == 0 ? this.props.dmcusername : this.state.UserName} />
                                        {/*<Form.Control type="text" name="dmcusername" required*/}
                                        {/*    placeholder="UserName" onChange={e => onChangeHandler(e.target.value)} Value={this.state.UserName}  />*/}
                                    </Form.Group>
                                    <div className="AutoCompletediv">
                                        {this.state.filteredSuggestions && this.state.filteredSuggestions.map((Suggestions, i) =>
                                            <div key={i} className="AutoComplete" onClick={() => onSuggestionHandler(Suggestions.empName, Suggestions.emailAddress, Suggestions.positionID, Suggestions.departmentName)}> {Suggestions.empName} </div>
                                        )}
                                    </div>
                                    <Form.Group controlId="dmcuseremail" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>UserEmail</Form.Label>
                                        <Form.Control type="text" name="dmcuseremail" required
                                            Value={this.state.UserEmail == "" && this.state.Change == 0 ? this.props.dmcuseremail : this.state.UserEmail}
                                            placeholder="UserEmail" />
                                    </Form.Group>
                                    <Form.Group controlId="dmcempid" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>UserID</Form.Label>
                                        <Form.Control type="text" name="dmcempid" required
                                            Value={this.state.UserID == "" && this.state.Change == 0 ? this.props.dmcempid : this.state.UserID}
                                            placeholder="UserID" disabled />
                                    </Form.Group>
                                    <Form.Group controlId="homecode2" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>Department</Form.Label>
                                        <Form.Control type="text" name="homeCode2"
                                            Value={this.state.HomeCode2 == "" && this.state.Change == 0 ? this.props.homeCode2 : this.state.HomeCode2}
                                                placeholder="Department" disabled  />
                                    </Form.Group>
                                    <Form.Group controlId="dmcUserRoleId" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>User Roles</Form.Label>
                                        <Form.Select as="select" required multiple>
                                            <option disabled value={''} >{'Select Roles (use ctrl for multiple select)'}</option>
                                            {this.state.UserRoles.map(UserRole =>
                                                this.props.dmcUserRoleNames.indexOf(UserRole.roleName) > -1 ?
                                                    <option value={UserRole.dmcUserRoleId} selected>{UserRole.roleName}</option>
                                                    :
                                                    <option value={UserRole.dmcUserRoleId}>{UserRole.roleName}</option>

                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group controlId="dmcIsAdmin" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label style={{ width: "23%", marginTop: "-2px" }}>Is Admin</Form.Label>
                                        <Form.Check
                                            name="dmcIsAdmin"
                                            type="checkbox"
                                            defaultChecked={this.props.isAdmin}

                                        />
                                    </Form.Group>
                                    <Form.Group >
                                        <div style={{ width: "100%", textAlign: "center" }} >
                                            <Button style={{ backgroundColor: "black", color: "white" }} type="submit">
                                                Update User
                                            </Button>
                                        </div>
                                    </Form.Group>
                                    <Form.Group >
                                        <div style={{ width: "100%", textAlign: "center", paddingTop: "10px" }} >
                                                <span className="Msg" >{this.state.Msg}</span>
                                                <span className="ErrorMsg" >{this.state.ErrorMsg}</span>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        </Modal.Body>
                    </div>
                </Modal>
            </div>
        )
    }

}