import React, { Component } from 'react';
import '../../../../src/App.css';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { AddDmcUsersModal } from './AddDmcUsersModal';
import { EditDmcUsersModal } from './EditDmcUsersModal';
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import logo from '../../../Images/DerrickLogo_100px.jpg';

export class DmcUsers extends Component {

    constructor(props) {
        super(props);
        this.state = { Users: [], addModalShow: false, editModalShow: false, isActive: true }
    }


    refreshList() {
        fetch('Admin/GetAllUserDetails')
            .then(response => response.json())
            .then(data => {
                this.setState({ Users: data });
            });
    }

    componentDidMount() {
        this.setState({ isActive: true });
        this.refreshList();
        this.setState({ isActive: false });
    }

    componentDidUpdate() {
        if (localStorage.getItem("UpdateDrawing") == "yes") {
            this.setState({ isActive: true });
            this.refreshList();
            this.setState({ isActive: false });
            localStorage.setItem('UpdateDrawing', null)
        }
    }

    deleteEmp(Userid) {
        if (window.confirm('Are you sure?')) {
            this.setState({ isActive: true });
            fetch('Admin/Delete/' + Userid, {
                method: 'DELETE',
                header: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            this.setState({ isActive: false });
        }
    }
    render() {
        const { Users, dmcUserID, dmcUserName, dmcUserEmail, dmcEmpId, homeCode2, dmcUserRoleNames = "", isAdmin, doj } = this.state;
        let addModalClose = () => this.setState({ addModalShow: false });
        let editModalClose = () => this.setState({ editModalShow: false });
        return (
            <>
            <div className={this.state.isActive == true ? 'loading' : ''} >
                <ButtonToolbar>
                    <Button className="btnadd btn-sm btn-outline-dark ReadOnlyCS"
                        onClick={() => this.setState({ addModalShow: true })}>
                        <FaPlus /></Button>

                    <AddDmcUsersModal show={this.state.addModalShow}
                        onHide={addModalClose} />
                </ButtonToolbar>
                <div>
                    <div style={{ marginTop: "15px", scrollbarColor: "Darkgray #808080", scrollbarWidth: "thin" }} >
                        <div className="table-container" >
                            <Table striped bordered hover size="sm" >
                                <thead>
                                    <tr>
                                        <th style={{ width: "7%" }}>Action</th>
                                        <th>UserName</th>
                                        <th>UserEmail</th>
                                        <th>UserID</th>
                                        <th>Department</th>
                                        <th >UserRole</th>
                                    {/*  <th>IsAdmin</th>*/}

                        </tr>
                    </thead>
                            <tbody>
                                {Users.map(user =>

                                    <tr key={user.dmc_User_ID}>
                                        <td>
                                            <ButtonToolbar>
                                                <Button className="mr-1" variant="info"
                                                    onClick={() => this.setState({
                                                        editModalShow: true,
                                                        dmcUserID: user.dmcUserID, dmcUserName: user.dmcUserName, dmcUserEmail: user.dmcUserEmail,
                                                        dmcEmpId: user.dmcEmpId, homeCode2: user.homeCode2, dmcUserRoleNames: user.dmcUserRoleNames, isAdmin: user.isAdmin
                                                    })}>
                                                    <FaEdit />
                                                </Button>

                                                {/*<Button className="mr-1" variant="danger"*/}
                                                {/*    onClick={() => this.deleteEmp(user.DmcUserID)}>*/}
                                                {/*    <FaTrash />*/}
                                                {/*</Button>*/}
                                            </ButtonToolbar>

                                        </td>
                                        <td>{user.dmcUserName}</td>
                                        <td>{user.dmcUserEmail}</td>
                                        <td>{user.dmcEmpId}</td>
                                        <td>{user.homeCode2}</td>
                                        <td style={{ wordWrap: "break-word" }}>{user.dmcUserRoleNames}</td>
                                        {/*  <td>{user.isAdmin}</td>*/}


                                    </tr>)}
                            </tbody>

                        </Table>
                    </div>
                </div>
                </div>
                <div style={{  display: "none" }}> <img src={logo} style={{ height: "5vh" }} />   </div >
                </div >
                <EditDmcUsersModal show={this.state.editModalShow}
                    onHide={editModalClose}
                    dmcuserid={dmcUserID}
                    dmcusername={dmcUserName}
                    dmcuseremail={dmcUserEmail}
                    dmcempid={dmcEmpId}
                    homeCode2={homeCode2}
                    dmcUserRoleNames={dmcUserRoleNames}
                    isAdmin={isAdmin}
                    doj={doj}
                />
                </>
        )
    }
}
export default DmcUsers;