import React, { Component } from 'react';
import { msalConfig } from './MsalConfig';
import { UserAgentApplication } from 'msal';
import { Modal, Button, Row, Col, Form, Image, ButtonToolbar } from 'react-bootstrap';
import logo from '../Images/derrick_full_logo.png'



export const msalAuth = new UserAgentApplication({
    auth: msalConfig
});

export function withAuth(HocComponent) {
    return class extends Component {

        constructor(props) {
            super(props);

            this.state = {
                isAuthenticated: false,
                user: {},
                renewIframe: false,
                hasError: false,
                errorMessage: null,
                requestID:0,
            };
        }

        async componentWillMount() {
            if (sessionStorage.getItem("isClickOnLogin") == "true") {
                let userAccount = msalAuth.getAccount();
                if (!userAccount) {
                    msalAuth.loginRedirect({});
                    return;
                } else {
                    this.setState({
                        isAuthenticated: true,
                        user: userAccount,
                        requestID: sessionStorage.getItem("ReqID")
                    });
                    sessionStorage.setItem("userName", userAccount.userName);
                    // SessionManager.setUserSession(userAccount.userName, userAccount.name);
                }
            }

            //msalAuth.handleRedirectCallback(() => {
            //    debugger;
            //    let userAccount = msalAuth.getAccount();

            //    this.setState({
            //        isAuthenticated: true,
            //        user: userAccount
            //    });
            //}, (authErr, accountState) => {  // on fail
            //    console.log(authErr);

            //    this.setState({
            //        hasError: true,
            //        errorMessage: authErr.errorMessage
            //    });
            //});

            //if (msalAuth.isCallback(window.location.hash)) {
            //    this.setState({
            //        auth: {
            //            renewIframe: true
            //        }
            //    });
            //    return;
            //}

            //let userAccount = msalAuth.getAccount();
            //if (!userAccount) {
            //    msalAuth.loginRedirect({});
            //    return;
            //} else {
            //    this.setState({
            //        isAuthenticated: true,
            //        user: userAccount
            //    });
            //}
        }

        onSignIn() {
            msalAuth.loginRedirect({});
        }

        onSignOut() {
            sessionStorage.setItem("isClickOnLogin", false);
            msalAuth.logout();
        }

        fnLogin = () => {
            if (window.location.href.indexOf("ReqID") > -1) {
                const searchParams = new URLSearchParams(window.location.search);
                const paramValue = searchParams.get('ReqID');
                sessionStorage.setItem("ReqID", paramValue);
                const urlWithoutParameters = window.location.origin;
                window.location.href = urlWithoutParameters;
            }
            else {
                sessionStorage.setItem("ReqID", 0);
            }
           
            sessionStorage.setItem("isClickOnLogin", true);
            msalAuth.handleRedirectCallback(() => {
                let userAccount = msalAuth.getAccount();

                this.setState({
                    isAuthenticated: true,
                    user: userAccount
                });
            }, (authErr, accountState) => {  // on fail
                console.log(authErr);

                this.setState({
                    hasError: true,
                    errorMessage: authErr.errorMessage
                });
            });

            if (msalAuth.isCallback(window.location.hash)) {
                this.setState({
                    auth: {
                        renewIframe: true
                    }
                });
                return;
            }

            let userAccount = msalAuth.getAccount();
            if (!userAccount) {
                msalAuth.loginRedirect({});
                return;
            } else {
                this.setState({
                    isAuthenticated: true,
                    user: userAccount
                });

                sessionStorage.setItem("userName", userAccount.userName);
                // SessionManager.setUserSession(userAccount.userName, userAccount.name);
            }
        }

        render() {
            if (this.state.renewIframe) {
                return <div>hidden renew iframe - not visible</div>;
            }
           if (this.state.isAuthenticated) {
                return <HocComponent auth={this.state} onSignIn={() => this.onSignIn()} onSignOut={() => this.onSignOut()} Url={window.location.href} {...this.props} />;
            }

            if (this.state.hasError) {
                return <div>{this.state.errorMessage}</div>;
            }
           

            //return <div>Login in progress...</div>;
            return (
                <>
                    <div id="root">
                        <div class="container-fluid">
                            <div class="row" style={{ marginTop: "0%", marginLeft: "-18%", backgroundColor: "black", width: "100%" }}>
                                <img src={logo} class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3" style={{ flex: "0 0 23%", maxWidth: "20%" }} />
                            </div>
                            <div class="row" style={{ textAlign: "Center", marginTop: "5%"}}>
                                <span class="h1 mx-auto">Derrick | DRR Engineering &nbsp;&nbsp;</span>
                            </div>
                            <div class="row" style={{ textAlign: "Center", marginTop: "2%" ,display:"block"}}>
                                    {/*<Form>*/}
                                    <button  class="btn btn-light" style={{ width:"200px", border: "solid", bordercolor: "#007935", borderwidth: "2px", color: "black", fontweight: "500", fontfamily: "Segoe UI", fontsize: "16px" }} onClick={this.fnLogin}>
                                        <span style={{ fontsize: "18px", margin: "3px 3px 5px 3px", whitespace: "nowrap", padding: "0px 30px 0px 30px", color: "#3d3131" }}>Sign In</span>
                                    </button>
                                   {/* </Form >*/}
                           </div>

                            {/*                                <br /><br /><br />*/}
                            {/* </div>*/}
                               
                          {/*  </div>*/}
                    </div></div>



                </>)
        }
    };
}
