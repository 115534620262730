import React, { Component } from 'react';
import { FaPlus, FaDownload, FaPrint, FaSpinner } from "react-icons/fa";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Button, Form, ButtonToolbar } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap-accordion'
import 'react-bootstrap-accordion/dist/index.css'
import { AddDrawing } from '../Dashboard/AddDrawing';
import { FileList } from '../../pages/File/FileList';
import '../Dashboard/Dashboard.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle'
import { UserLogin } from '../../UserLogin';
import logo from '../../../Images/derrick_full_logo.png';

export class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DeptLists: [], AllUsers: [], AffDepts: [], filteredSuggestions: [], filteredSuggestionsRevisedBy: [], UserName:"",
            UserID:0, Msg: '', startDate: new Date(), EditDeptID: 0, EditReason: '', ExistsLoginRoleName:"",
            EditGA: '', EditUrgent: '', EditStchg: '', addModalShow: false, editModalShow: false, addFileModalShow: false, LoginRoleName:"",
            ApprlByArexAdmin: [], ApprlByAdmin: [], ReqInput: '', ProjectDeliveryListsOne: [], ProjectDeliveryListsTwo: [],
            RequirementList: [], OutEvidentList: [], OutValidEvidentList: [], RevisionDate: new Date(), /*StatusList: [],*/
            RevisedBy: '', RevisedByID: 0, EditBusinessSegment: '', EditProductFamily: '', EditProductModel: '',
            EditInternalContainmentPlan: '', EditFieldContainmentPlan: '', EditContComment: '', EditRevNumber: '', EditRevComments: '',
            EditStatusID: '', TabTitle: 'New Request', btnsubmit: 'Save', LoginUserMailID: localStorage.getItem("LoginUserID"), DisableVal: true
            , Stchgs: ["No"], UrgentLists: ["IMMEDIATE (Production On Hold Until Implementation)", "Change For Future Production"]
            , InternalPlanList: [], FieldPlanList: []
            , BusinessSegmentList: [], ProductFamilyList: [], ApprlByArexAdminVal: "", ApprlByAdminVal: ""
            , IsApprovedByAdminAndAtex: false, isActive: true, IsAtexRequired: '', FileCopy: '', AutoGenID: 0, StatusDRRComplete: false
        }

    }


    componentDidMount() {
      
        this.GetAllDetails();

    }

    render() {

        let addModalClose = () => this.setState({ addModalShow: false });

        const myComponent = {
            /*  width: '500px',*/
            /*height: '550px',*/
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: 'white',
            marginBottom: '50px'


        };
        
        return (

            <>
                <div>
                    <div style={{ width: "100%", backgroundColor: "black", height: "50px", display: "flex" }}>
                        <div style={{ width: "80%", color: "white" }}> <Link to={{
                            pathname: '/',

                        }}><img src={logo} style={{ height: "5vh" }} /> </Link></div>
                        <div style={{ width: "20%", color: "white", textAlign: "right", paddingRight: "20px" }}> <UserLogin {...this.props} /></div>
                    </div>
                    <div style={{ marginTop: "5px", padding: "10px" }}>
                <div className={this.state.isActive == true ? 'loading' : ''}>
                    <Form>
                        <div >
                            <Tabs
                                defaultActiveKey="Request"
                                id="uncontrolled-tab-example"
                                className="mb-4"

                            >
                                <Tab eventKey="Request" title="Request" >
                                    <div className='App' style={myComponent}>
                                        <Accordion title={this.state.TabTitle} show={true}  >
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="ProposedByID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Proposed By</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Control type="text" name="ProposedByID" disabled={this.state.DisableVal}
                                                                    placeholder="ProposedBy" value={this.state.UserName} required />
                                                            </div>
                                                        </Form.Group>

                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="ProposedByDate" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Proposed By Date</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <DatePicker
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange} disabled={this.state.DisableVal}
                                                                    name="startDate"
                                                                    dateFormat="MM/dd/yyyy" className="form-control" name="ProposedByDate" placeholder="Proposed By Date"
                                                                />

                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="DeptProposingid" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Dept Proposing</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="DeptProposingid" disabled={this.state.DisableVal} required>
                                                                    <option value={''}  >{'Select Dept Proposing'}</option>
                                                                    {this.state.DeptLists.map(Dept =>
                                                                        Dept.dmcDepartmentId == this.state.EditDeptID ?
                                                                            <option value={Dept.dmcDepartmentId} selected>{Dept.homeCode2}</option>
                                                                            :
                                                                            <option value={Dept.dmcDepartmentId}>{Dept.homeCode2}</option>


                                                                    )}
                                                                </Form.Select>

                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="Urgency" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Urgency</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="Urgency" disabled={this.state.DisableVal} required>
                                                                    <option value={''} >{'Select Urgency'}</option>
                                                                    {this.state.UrgentLists.map(UrgentList =>
                                                                        UrgentList == this.state.EditUrgent ?
                                                                            <option value={UrgentList} selected>{UrgentList}</option>
                                                                            :
                                                                            <option value={UrgentList}>{UrgentList}</option>


                                                                    )}
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        {this.state.EditStchg && this.state.Stchgs.map(AffDept =>
                                                            <Form.Group controlId="StructuralChange" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Structural Change</Form.Label>
                                                                </div>
                                                                <div style={{ width: "60%" }}>
                                                                    <div style={{ width: "100%", display: "flex", fontSize: "18px" }}>
                                                                        <div style={{ width: "3%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="Yes"
                                                                                type="radio"
                                                                                Name="StructuralChange"
                                                                                label="Yes" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.EditStchg == "Yes" ? true : false}
                                                                               
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "10%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="No"
                                                                                type="radio"
                                                                                Name="StructuralChange"
                                                                                label="No" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.EditStchg == "No" ? true : false}
                                                                                
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "63%" }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        {this.state.IsAtexRequired && this.state.Stchgs.map(AffDept =>
                                                            <Form.Group controlId="IsAtexRequired" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Atex</Form.Label>
                                                                </div>
                                                                <div style={{ width: "60%" }}>
                                                                    <div style={{ width: "100%", display: "flex", fontSize: "18px" }}>
                                                                        <div style={{ width: "3%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>

                                                                            <Form.Check
                                                                                value="Yes"
                                                                                type="radio"
                                                                                name="IsAtexRequired"
                                                                                label="Yes" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.IsAtexRequired == "Yes" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "10%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>

                                                                            <Form.Check
                                                                                value="No"
                                                                                type="radio"
                                                                                Name="IsAtexRequired"
                                                                                name="IsAtexRequired"
                                                                                label="No" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.IsAtexRequired == "No" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "63%" }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="GARef" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>G/A Ref</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Control type="text" name="GARef" disabled={this.state.DisableVal}
                                                                    placeholder="G/A Ref" defaultValue={this.state.EditGA} required />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        {this.state.FileCopy && this.state.Stchgs.map(AffDept =>
                                                            <Form.Group controlId="FileCopy" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>FileCopy</Form.Label>
                                                                </div>
                                                                <div style={{ width: "60%" }}>
                                                                    <div style={{ width: "100%", display: "flex", fontSize: "18px" }}>
                                                                        <div style={{ width: "3%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="Yes"
                                                                                type="radio"
                                                                                Name="FileCopy"
                                                                                label="Yes" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.FileCopy == "Yes" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "10%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="No"
                                                                                type="radio"
                                                                                Name="FileCopy"
                                                                                label="No" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.FileCopy == "No" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "63%" }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}

                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="Reason" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Reason</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Control type="text" name="Reason" as="textarea" disabled={this.state.DisableVal}
                                                                    placeholder="Reason" defaultValue={this.state.EditReason} required />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>

                                                </div>
                                            </div>

                                        </Accordion>
                                        <Accordion title="Drawing" style={{ width: "80%" }}>
                                            <div style={{ width: "80%" }} >
                                                <div style={{ width: "100%" }}>
                                                  
                                                        <AddDrawing revID={this.props.ReqID} autoGenID={this.state.AutoGenID} drawingID="0" disabled={this.state.DisableVal} show={this.state.addModalShow}
                                                            onHide={addModalClose} />
                                                </div>

                                            </div>
                                        </Accordion>
                                        <Accordion title="Affected Depts">
                                            {this.state.AffDepts && this.state.AffDepts.map((AffDept, i) =>
                                                <Form.Group controlId={AffDept.dmcNotificationRoleId} style={{ display: "flex", paddingBottom: "15px" }}>
                                                    <div style={{ width: "10%", display: "flex" }}>
                                                        <div style={{ width: "25%" }}>
                                                            <Form.Check
                                                                value="Yes"
                                                                type="radio"
                                                                Name={AffDept.dmcNotificationRoleId}
                                                                label="Yes" disabled={this.state.DisableVal == true ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase() == "users" && AffDept.dmcNotificationName == "CPI(ATEX Only)" ?
                                                                        true
                                                                        :
                                                                        false}
                                                                defaultChecked={AffDept.reason == "Yes" ? true : false}
                                                            />
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                        </div>
                                                        <div style={{ width: "25%" }}>
                                                            <Form.Check
                                                                value="No"
                                                                type="radio"
                                                                Name={AffDept.dmcNotificationRoleId}
                                                                label="No" disabled={this.state.DisableVal == true ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase() == "users" && AffDept.dmcNotificationName == "CPI(ATEX Only)" ?
                                                                        true
                                                                        :
                                                                        false}
                                                                defaultChecked={AffDept.reason == "No" ? true : false}

                                                            />
                                                        </div>
                                                        <div style={{ width: "30%" }}>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "92%" }}>
                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>{AffDept.dmcNotificationName}</Form.Label>
                                                    </div>
                                                </Form.Group>

                                            )}
                                        </Accordion>
                                        <Accordion title="Attachment">
                                            <div style={{ width: "80%" }} >
                                                <FileList RevID={this.props.ReqID} disabled={this.state.DisableVal} />

                                            </div>

                                        </Accordion>
                                        
                                            <Accordion title="Admin Approval">
                                                <div>
                                                    <div style={{ textAlign: "left", fontWeight: "bold" }}>ATEX APPROVAL (edit only by Atex Admin)</div>
                                                    <div>
                                                        {this.state.ApprlByArexAdmin && this.state.ApprlByArexAdmin.map((str, i) =>
                                                            <Form.Group controlId={str.id} style={{ display: "flex", paddingBottom: "15px" }} >
                                                                <div style={{ width: "10%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Approval Status</Form.Label>
                                                                </div>
                                                                <div style={{ width: "90%", display: "flex", textAlign: "left" }}>
                                                                    <div style={{ width: "10%" }}>
                                                                        <Form.Check
                                                                            value="Approved"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="Approved" disabled={this.state.DisableVal}

                                                                            defaultChecked={str.val == "Approved" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "10%" }}>
                                                                        <Form.Check
                                                                            value="Rejected"
                                                                            type="radio"
                                                                            name={str.id}

                                                                            label="Rejected" disabled={this.state.DisableVal}
                                                                            defaultChecked={str.val == "Rejected" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "80%" }}>
                                                                        <Form.Check
                                                                            value="None"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="None" disabled={this.state.DisableVal}
                                                                            defaultChecked={str.val == null ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}
                                                    </div>

                                                </div>.
                                                <div>
                                                    <div style={{ textAlign: "left", fontWeight: "bold" }}>REQUEST APPROVAL (edit only by Approval Admin)</div>
                                                    <div>
                                                        {this.state.ApprlByAdmin && this.state.ApprlByAdmin.map((str, i) =>
                                                            <Form.Group controlId={str.id} style={{ display: "flex", paddingBottom: "15px" }} >
                                                                <div style={{ width: "10%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Approval Status</Form.Label>
                                                                </div>
                                                                <div style={{ width: "90%", display: "flex", textAlign: "left" }}>
                                                                    <div style={{ width: "10%" }}>
                                                                        <Form.Check
                                                                            value="Approved"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="Approved" disabled={this.state.DisableVal}
                                                                            defaultChecked={str.val == "Approved" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "10%" }}>
                                                                        <Form.Check
                                                                            value="Rejected"
                                                                            type="radio"
                                                                            name={str.id}

                                                                            label="Rejected" disabled={this.state.DisableVal}
                                                                            defaultChecked={str.val == "Rejected" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "80%" }}>
                                                                        <Form.Check
                                                                            value="None"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="None" disabled={this.state.DisableVal}
                                                                            defaultChecked={str.val == null ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>
                                            </Accordion>
                                    </div>

                                </Tab>

                                <Tab eventKey="Record" title="Record" >
                                    <div className='App' style={myComponent}>
                                        <Accordion title="Requirement" >
                                            <div>
                                                <div style={{ border: "1px solid", padding: "10px" }}>
                                                    <div style={{ fontWeight: "bold", textAlign: "left", backgroundColor: "#D3D3D3", width: "80%" }} >
                                                        <span>*Required If Structural Change Is Equal To Yes</span>
                                                    </div>
                                                    <div style={{ display: "flex", width: "80%", marginTop: "10px" }} >
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="BusinessSegmentID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Business Segment</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%" }}>
                                                                        <Form.Select as="select" name="BusinessSegmentID"
                                                                            disabled={this.state.DisableVal}
                                                                        >

                                                                            <option value={''}  >{'Select Business Segment'}</option>
                                                                            {this.state.BusinessSegmentList.map(Bus =>
                                                                                this.state.EditBusinessSegment == Bus.businessSegmentName ?
                                                                                    <option value={Bus.businessSegmentName} selected>{Bus.businessSegmentName}</option>
                                                                                    :
                                                                                    <option value={Bus.businessSegmentName} >{Bus.businessSegmentName}</option>


                                                                            )}

                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "5%" }}>
                                                        </div>
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="ProductFamilyID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Product Family</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%" }}>
                                                                        <Form.Select as="select" name="ProductFamilyID"
                                                                            disabled={this.state.DisableVal}
                                                                        >
                                                                            <option value={''} >{'Select Product Family'}</option>
                                                                            {this.state.ProductFamilyList.map(Prod =>
                                                                                this.state.EditProductFamily == Prod.productFamilyName ?
                                                                                    <option value={Prod.productFamilyName} selected>{Prod.productFamilyName}</option>
                                                                                    :
                                                                                    <option value={Prod.productFamilyName} >{Prod.productFamilyName}</option>


                                                                            )}

                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", width: "80%" }} >
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="ProductModelID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Product Model</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%" }}>
                                                                        <Form.Control type="text" name="ProductModelID"
                                                                            disabled={this.state.DisableVal}
                                                                            placeholder="Product Model" defaultValue={this.state.EditProductModel} />

                                                                        
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "5%" }}>
                                                        </div>
                                                        <div style={{ width: "45%" }}  >
                                                        </div>
                                                    </div>

                                                </div>
                                                <div style={{ overflowY: "auto", height: "50vh", marginBottom: "10px" }}>
                                                    {this.state.RequirementList && this.state.RequirementList.map((req, i) =>
                                                        <div style={{ width: "98%" }} >
                                                            <Form.Group controlId={"req" + req.dmcRequirementId}>
                                                                <div style={{ display: "flex", width: "100%", paddingTop: "20px", paddingLeft: "20px" }}>
                                                                    <div style={{ width: "20%", fontWeight: "bold", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                        <Form.Check
                                                                            value={req.dmcRequirementId}
                                                                            type="checkbox"
                                                                            Name={"Chkreq" + req.dmcRequirementId}
                                                                            disabled={this.state.DisableVal}
                                                                            label={req.requirementName} 
                                                                            defaultChecked={req.responce == "Yes" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: this.state.ReqInput.indexOf("DisableReq" + req.dmcRequirementId + "^") > -1 ? "flex" : "none", width: "100%", textAlign: "left" }}>
                                                                        <div style={{ width: "25%" }}>
                                                                            <span style={{ fontWeight: "700", fontSize: "14px" }} >Design Input Requirements </span>
                                                                            <Form.Control type="text" Name={"InputReq" + req.dmcRequirementId}
                                                                                disabled={this.state.DisableVal}
                                                                                placeholder="Design Input Requirements" defaultValue={req.dsignInputRequirements} />
                                                                        </div>
                                                                        <div style={{ width: "35%", paddingLeft: "30px" }}>
                                                                            <span style={{ fontWeight: "700", fontSize: "14px" }} >Verification (Design Outputs Meet Design Inputs)</span>
                                                                            <Form.Select as="select" Name={"VerifOutEv" + req.dmcRequirementId}
                                                                                disabled={this.state.DisableVal}
                                                                            >
                                                                                <option value={0} >Select Verification Output Evidence</option>
                                                                                {this.state.OutEvidentList.map(out =>
                                                                                    req.dmcValidationOutputEvidenceId == out.dmcOutputEvidenceId && req.dmcValidationOutputEvidenceId > 0 ?
                                                                                        <option value={out.dmcOutputEvidenceId} selected>{out.evidenceName}</option>
                                                                                        :
                                                                                        <option value={out.dmcOutputEvidenceId} >{out.evidenceName}</option>


                                                                                )}

                                                                            </Form.Select>
                                                                        </div>
                                                                        <div style={{ width: "40%", paddingLeft: "30px" }}>
                                                                            <span style={{ fontWeight: "700", fontSize: "14px" }} >Validation (Design Meets Customer Application Requirements)</span>
                                                                            <Form.Select as="select" Name={"ValidOutEv" + req.dmcRequirementId}
                                                                                disabled={this.state.DisableVal}>
                                                                                <option value={0} >Select validation Output Evidence</option>
                                                                                {this.state.OutValidEvidentList.map(out =>
                                                                                    req.dmcVerificationOutputEvidenceId == out.dmcOutputEvidenceId && req.dmcVerificationOutputEvidenceId > 0 ?
                                                                                        <option value={out.dmcOutputEvidenceId} selected>{out.evidenceName}</option>
                                                                                        :
                                                                                        <option value={out.dmcOutputEvidenceId} >{out.evidenceName}</option>


                                                                                )}

                                                                            </Form.Select>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: this.state.ReqInput.indexOf("DisableReq" + req.dmcRequirementId + "^") > -1 ? "flex" : "none", width: "100%", paddingTop: "20px", paddingLeft: "20px", textAlign: "left" }}>
                                                                    <div style={{ width: "20%" }}>

                                                                    </div>
                                                                    <div style={{ display: "flex", width: "100%" }}>
                                                                        <div style={{ width: "25%" }}>
                                                                        </div>
                                                                        <div style={{ width: "35%", paddingLeft: "30px" }}>
                                                                            <Form.Control type="text" Name={"VerifSupEv" + req.dmcRequirementId} as="textarea"
                                                                                disabled={this.state.DisableVal}
                                                                                placeholder="Verification Supporting Evidence" defaultValue={req.validationSupportingEvidence} />
                                                                        </div>
                                                                        <div style={{ width: "40%", paddingLeft: "30px" }}>
                                                                            <Form.Control type="text" Name={"ValidSupEv" + req.dmcRequirementId} as="textarea"

                                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                    true
                                                                                    :
                                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                        false
                                                                                        :
                                                                                        true}
                                                                                placeholder="Validation Supporting Evidence" defaultValue={req.verificationSupportingEvidence} />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </Form.Group>

                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Accordion>
                                        <Accordion title="Containment Plans">
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="InterContPlanID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Internal Containment Plan</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="InterContPlanID"

                                                                    disabled={this.state.DisableVal}>
                                                                    <option value={''}  >{'Select Internal Containment Plan'}</option>
                                                                    {this.state.InternalPlanList.map(Internal =>
                                                                        this.state.EditInternalContainmentPlan == Internal.internalContainmentPlanName ?
                                                                            <option value={Internal.internalContainmentPlanName} selected>{Internal.internalContainmentPlanName}</option>
                                                                            :
                                                                            <option value={Internal.internalContainmentPlanName} >{Internal.internalContainmentPlanName}</option>


                                                                    )}

                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="FieldContPlanID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Field Containment Plan</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="FieldContPlanID"

                                                                    disabled={this.state.DisableVal}>
                                                                    <option value={''}  >Select Field Containment Plan</option>
                                                                    {this.state.FieldPlanList.map(Field =>
                                                                        this.state.EditFieldContainmentPlan == Field.fieldContainmentPlanName ?
                                                                            <option value={Field.fieldContainmentPlanName} selected>{Field.fieldContainmentPlanName}</option>
                                                                            :
                                                                            <option value={Field.fieldContainmentPlanName} >{Field.fieldContainmentPlanName}</option>


                                                                    )}

                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%" }}>
                                                    <Form.Group controlId="ContCommentID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                        <div style={{ width: "18%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Comment</Form.Label>
                                                        </div>
                                                        <div style={{ width: "77%" }}>
                                                            <Form.Control type="text" Name="ContCommentID" as="textarea"

                                                                disabled={this.state.DisableVal}
                                                                placeholder="Comment" defaultValue={this.state.EditContComment} />
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                        </Accordion>
                                        <Accordion title="Project Deliverables">
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%", paddingBottom: "15px" }}>
                                                    <div style={{ width: "100%", textAlign: "left", backgroundColor: "#D3D3D3" }}>
                                                        <span style={{ fontWeight: "bold" }} >Project Deliverables (select at least one from list)</span>
                                                    </div>
                                                    <div style={{ width: "100%", marginTop: "10px" }}>
                                                        {this.state.ProjectDeliveryListsOne && this.state.ProjectDeliveryListsOne.map((Pro, i) =>
                                                            <Form.Group controlId={"ProOne" + Pro.dmcProjectDeliverableId} style={{ display: "flex" }}>
                                                                <div style={{ width: "25%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "right" }}>{Pro.projectDeliverableName}:</Form.Label>
                                                                </div>
                                                                <div style={{ width: "2%" }}>
                                                                </div>
                                                                <div style={{ width: "73%" }}>
                                                                    <Form.Check
                                                                        value={Pro.dmcProjectDeliverableId}
                                                                        type="checkbox"
                                                                        Name={"ProOne" + Pro.dmcProjectDeliverableId}
                                                                        label=""
                                                                        disabled={this.state.DisableVal}
                                                                        defaultChecked={Pro.response == "Yes" ? true : false}
                                                                    />
                                                                </div>

                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%" }}>
                                                    <div style={{ width: "100%", textAlign: "left", backgroundColor: "#D3D3D3" }}>
                                                        <span style={{ fontWeight: "bold" }} >Email Notification Groups</span>
                                                    </div>
                                                    <div style={{ width: "100%", marginTop: "10px" }}>
                                                        {this.state.ProjectDeliveryListsTwo && this.state.ProjectDeliveryListsTwo.map((Pro, i) =>
                                                            <Form.Group controlId={"ProTwo" + Pro.dmcNotificationRoleId} style={{ display: "flex" }}>
                                                                <div style={{ width: "2%" }}>
                                                                </div>
                                                                <div style={{ width: "92%", textAlign: "left" }}>
                                                                    <Form.Check
                                                                        value={Pro.dmcNotificationRoleId}
                                                                        type="checkbox"
                                                                        Name={"ProTwo" + Pro.dmcNotificationRoleId}
                                                                        label={Pro.dmcNotificationName}

                                                                        disabled={this.state.DisableVal}
                                                                        defaultChecked={Pro.response == "Yes" ? true : false}
                                                                    />
                                                                </div>

                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </Accordion>
                                        <Accordion title="Review/Approval Section">
                                            <div style={{ width: "80%" }} >
                                                <div style={{ fontWeight: "bold", textAlign: "left", backgroundColor: "#D3D3D3" }}>
                                                    <span >Production Design</span>
                                                </div>
                                                <div style={{ width: "100%", display: "flex", paddingBottom: "20px", marginTop: "10px" }}>
                                                    <Form.Group controlId="RevisionDate" style={{ width: "30%", display: "flex" }}>
                                                        <div style={{ width: "50%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Revision Date</Form.Label>
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            <DatePicker
                                                                selected={this.state.RevisionDate}
                                                                onChange={this.revisionHandleChange}
                                                                name="startDate"

                                                                disabled={this.state.DisableVal}
                                                                dateFormat="MM/dd/yyyy" className="form-control" name="RevisionDate" placeholder="Revision Date"

                                                            />
                                                        </div>
                                                    </Form.Group>
                                                    <div style={{ width: "5%" }}>
                                                    </div>
                                                    <Form.Group controlId="RevisedByID" style={{ width: "40%", display: "flex" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Revised By</Form.Label>
                                                        </div>
                                                        <div style={{ width: "60%", position: "relative" }}>
                                                            <Form.Control type="text" name="RevisedByID"

                                                                disabled={this.state.DisableVal}
                                                                placeholder="RevisedBy"  value={this.state.RevisedBy} />
                                                           
                                                        </div>
                                                    </Form.Group>
                                                    <div style={{ width: "5%" }}>
                                                    </div>
                                                    <Form.Group controlId="RevNoID" style={{ width: "20%", display: "flex" }}>
                                                        <div style={{ width: "50%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Rev No</Form.Label>
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            <Form.Control type="text" Name="RevNoID" 

                                                                disabled={this.state.DisableVal}
                                                                placeholder="Rev No" defaultValue={this.state.EditRevNumber} />
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%" }}>

                                                    <Form.Group controlId="RevisionCommentID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                        <div style={{ width: "15%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Comment</Form.Label>
                                                        </div>
                                                        <div style={{ width: "85%" }}>
                                                            <Form.Control type="text" Name="RevisionCommentID" as="textarea" 

                                                                disabled={this.state.DisableVal}
                                                                placeholder="Comment" defaultValue={this.state.EditRevComments} />
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                            <div style={{ width: "80%" }} >
                                                <div style={{ fontWeight: "bold", textAlign: "left", backgroundColor: "#D3D3D3" }}>  <span  >Review/Approval Section</span></div>
                                                <div style={{ width: "100%", display: "flex", marginTop: "10px" }}>
                                                    <div style={{ width: "17%" }}>
                                                        <span style={{ display: "table-cell", textAlign: "left" }}>Complete</span>
                                                    </div>
                                                    <div style={{ width: "30%" }}>
                                                        <span style={{ float: "left" }}>
                                                            {this.state.EditStatusID && this.state.Stchgs.map(AffDept =>
                                                                <Toggle
                                                                    defaultChecked={this.state.EditStatusID == "4" ? true : false}
                                                                 
                                                                    disabled={this.state.DisableVal}
                                                                    id='cheese-status'
                                                                    icons={{
                                                                        checked: "YES",
                                                                        unchecked: "NO",
                                                                    }}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>

                                                    <div style={{ width: "65%" }}>

                                                    </div>

                                                </div>

                                            </div>
                                        </Accordion>
                                    </div>
                                </Tab>

                            </Tabs>

                        </div>
                        {/*<Form.Group >*/}
                        {/*    <div style={{ width: "100%", textAlign: "center", padding: "20px", backgroundColor: "white" }} >*/}

                        {/*        <Button style={{ backgroundColor: "black", color: "white" }} type="submit">*/}
                        {/*            {this.state.btnsubmit}*/}
                        {/*        </Button>*/}
                        {/*     */}{/*   <span style={{ padding: "2px" }}></span>*/}
                        {/*        */}{/*<Button style={{ backgroundColor: "black", color: "white" }}>*/}
                        {/*        */}{/*    Clear*/}
                        {/*        */}{/*</Button>*/}
                        {/*    </div>*/}

                        {/*</Form.Group>*/}
                    </Form>
                        </div>
                    </div>
                </div>
            </>

        )
    }
    GetAllDetails() {
        if (this.props.ReqID > 0) {
            fetch('DashBoard/GetRequestDetailsByID/' + this.props.ReqID)
                .then(response => response.json())
                .then(data => {
                    for (const str of data) {
                        this.setState({
                            TabTitle: "Update Request",
                            btnsubmit: "Update",
                            EditDeptID: str.dmcDepartmentId,
                            UserName: str.dmcUserName,
                            UserID: str.dmcUserId,
                            startDate: new Date(str.proposedByDate),
                            EditReason: str.reason,
                            EditGA: str.garef,
                            EditStchg: str.structuralChange == '' ? "NoSelect" : str.structuralChange,
                            EditUrgent: str.urgency,
                            ApprlByArexAdminVal: str.atexAppproval,
                            ApprlByAdminVal: str.adminApproval,
                            ApprlByArexAdmin: [{ id: "ApprlByArexAdmin", val: str.atexAppproval }],
                            ApprlByAdmin: [{ id: "ApprlByAdmin", val: str.adminApproval }],
                            IsApprovedByAdminAndAtex: str.adminApproval == "Approved" ? str.isAtexRequired == 'Yes' ? str.atexAppproval == "Approved" ? true : false : true : false,
                            EditBusinessSegment: str.businessSegment,
                            EditProductFamily: str.productFamily,
                            EditProductModel: str.productModel,
                            EditInternalContainmentPlan: str.internalContainmentPlan,
                            EditFieldContainmentPlan: str.fieldContainmentPlan,
                            EditContComment: str.contComment,
                            RevisedBy: str.revisedBy,
                            RevisedByID: str.revisedByID,
                            RevisionDate: new Date(str.strRevisionDate),
                            EditRevNumber: str.revNumber,
                            EditRevComments: str.revComments,
                            EditStatusID: str.dmcDrrstatusId,
                            IsAtexRequired: str.isAtexRequired == '' ? "NoSelect" : str.isAtexRequired,
                            FileCopy: str.fileCopy == '' ? "NoSelect" : str.fileCopy,
                           /* RequestedDate: str.requestedDate*/
                        });
                    }
                });

            /* this.GetAllDrawings();*/
            /*    this.AttachmentList();*/

        }
        else {

            fetch('DashBoard/GetAutoGenID', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }

            })
                .then(res => res.json())
                .then((result) => {
                    if (result.msg == "") {
                        this.setState({
                            AutoGenID: result.autoGenID
                        });
                    }
                    else {
                        alert(result.msg);
                    }

                },
                    (error) => {
                        alert('Failed');

                    })

            this.setState({
                ApprlByArexAdmin: [{ id: "ApprlByArexAdmin", val: null }],
                ApprlByAdmin: [{ id: "ApprlByAdmin", val: null }],
                EditStchg: "NoSelect",
                IsAtexRequired: "NoSelect",
                FileCopy: "NoSelect",
                EditStatusID: "1"

            });
            localStorage.setItem('CheckDrawing', null);
        }


        fetch('CommonDropDownLists/GetAllDeptList')
            .then(response => response.json())
            .then(data => {
                this.setState({ DeptLists: data });
            });

        fetch('CommonDropDownLists/GetAllUsersName')
            .then(response => response.json())
            .then(data => {
                this.setState({ AllUsers: data });
            });

        fetch('DashBoard/GetAffDeptList/' + this.props.ReqID)
            .then(response => response.json())
            .then(data => {
                this.setState({ AffDepts: data });
            });
        fetch('CommonDropDownLists/GetRequirementList/' + this.props.ReqID)
            .then(response => response.json())
            .then(data => {
                var val = "";
                for (const str of data) {
                    if (str.responce == "Yes")
                        val = val + "DisableReq" + str.dmcRequirementId + "^"
                }
                this.setState({ RequirementList: data, ReqInput: val });
            });
        fetch('CommonDropDownLists/GetBusinessSegmentList')
            .then(response => response.json())
            .then(data => {
                this.setState({ BusinessSegmentList: data });
            });
        fetch('CommonDropDownLists/GetProductFamilyList')
            .then(response => response.json())
            .then(data => {
                this.setState({ ProductFamilyList: data });
            });
        fetch('CommonDropDownLists/GetInternalContainmentPlanModelList')
            .then(response => response.json())
            .then(data => {
                this.setState({ InternalPlanList: data });
            });
        fetch('CommonDropDownLists/GetFieldContainmentPlanList')
            .then(response => response.json())
            .then(data => {
                this.setState({ FieldPlanList: data });
            });
        fetch('CommonDropDownLists/GetOutputEvidenceList')
            .then(response => response.json())
            .then(data => {
                this.setState({ OutEvidentList: data });
            });
        fetch('CommonDropDownLists/GetValidationOutputEvidenceList')
            .then(response => response.json())
            .then(data => {
                this.setState({ OutValidEvidentList: data });
            });
        fetch('CommonDropDownLists/GetProjectDeliveryListsOne/' + this.props.ReqID)
            .then(response => response.json())
            .then(data => {
                this.setState({ ProjectDeliveryListsOne: data });
            });
        fetch('CommonDropDownLists/GetFinalNotificationList/' + this.props.ReqID)
            .then(response => response.json())
            .then(data => {
                this.setState({ ProjectDeliveryListsTwo: data });
            });

        this.setState({ isActive: false });
    }


}
export default UserDetails