
import React, { Component, useState } from 'react';
import { Modal, Button, Row, Col, Form, Image, ButtonToolbar, } from 'react-bootstrap';
import { FaArrowAltCircleUp, FaCloudUploadAlt, FaArrowCircleDown, FaTrash } from "react-icons/fa";
import DragAndDrop from '../File/DragAndDrop'
import '../File/filecss.css';
import { Table } from 'react-bootstrap';
/*let dataList = new FormData();*/
export class FileList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Msg: '', files: [],
            data: new FormData(), fileLists: [], isActive: false
        };

    }
    AttachmentList() {
        if (this.props.RevID > 0) {
            this.setState({ isActive: true });
            fetch('DmcFiles/GetAllFiles/' + this.props.RevID)
                .then(response => response.json())
                .then(data => {
                    this.setState({ fileLists: data });
                    this.setState({ isActive: false });
                });                  
           
        }
    }

    componentDidMount() {
        if (this.props.RevID > 0) {
            this.AttachmentList();
          
           // alert(this.state.fileLists)
        }

    }
  
    fnSave = () => {

        if (this.props.RevID > 0) {
            let fileList = this.state.files
            let dataList = this.state.data
            //this.setState({ files: fileList })
            //this.setState({ data: dataList })
            if (fileList.length <= 0) {
                alert('Please Choose or Drag File/s');
            }
            else {
                this.setState({ isActive: true });
                fetch(('DmcFiles/CreateFiles/' + this.props.RevID), {
                    method: 'POST',
                    // headers: {
                    //    'Accept': 'application/json',
                    //    'Content-Type': 'application/json' 
                    //},
                    //body: JSON.stringify(listOfObjects)
                   body: dataList
                }).then(res => res.json())
                    .then((result) => {
                        this.setState({
                            Name: '',
                            Msg: result,
                            files: [],
                            data: [],
                        });
                        this.setState({ isActive: false });
                       this.AttachmentList();
                        setTimeout(() => {
                            this.setState({
                                Msg: ''
                            });
                        }, 1000)
                    },
                        (error) => {
                            alert('Failed' + ': ' + error);
                            this.setState({
                                Name: '',
                                Msg: '',
                                files: [],
                                data: [],
                            });
                           this.setState({ isActive: false });
                        })
            }
            //this.setState({ files: [] })
            //this.setState({ data: [] })
           // document.getElementsByName("upload_file")[0].value = ''

        }
        else {
            alert("Please Submit Request details before add Attachment");
        }
    }

   


    render() {

        const fnClear = () => {
            this.setState({ files: [] })
            this.setState({ data: [] })
            /*dataList = new FormData();*/
        }

        const handleDrop = (files) => {
            let fileList = this.state.files
            let dataList = new FormData();
            for (var i = 0; i < files.length; i++) {
                //if (!files[i].name && fileList.includes(files[i].name)==false) return
                if (fileList.includes(files[i].name) == false) {
                    let filteredSuggestions = []
                    filteredSuggestions = this.state.fileLists.filter(part => {
                        return part.fileName == files[i].name
                    });
                    if (filteredSuggestions.length > 0) {
                        if (window.confirm("The file '" + files[i].name + "' already exists. Press ok if you want to continue.")) {
                            fileList.push(files[i].name)
                            dataList.append('files', files[i]);
                            dataList.append('name', files[i].name);
                        }
                    }
                    else {
                        fileList.push(files[i].name)
                        dataList.append('files', files[i]);
                        dataList.append('name', files[i].name);
                    }
                   
                    
                }

            }
            //for (var i = 0; i < files.length; i++) {
            //    dataList.append('files', files[i]);
            //    dataList.append('name', files[i].name);
            //}
            this.setState({ files: fileList })
            this.setState({ data: dataList })
        }

        const handleInputChange = (event) => {
            let fileList = this.state.files
            let dataList = new FormData();
            if (fileList.includes(event.target.files[0].name) == false) {
                let filteredSuggestions = []
                filteredSuggestions = this.state.fileLists.filter(part => {
                    return part.fileName == event.target.files[0].name
                });
                if (filteredSuggestions.length > 0) {
                    if (window.confirm("The file '" + event.target.files[0].name + "' already exists. Press ok if you want to continue.")) {
                        fileList.push(event.target.files[0].name)
                        dataList.append('files', event.target.files[0]);
                        dataList.append('name', event.target.files[0].name);
                        this.setState({ files: fileList });
                        this.setState({ data: dataList })
                    }
                }
                else {
                    fileList.push(event.target.files[0].name)
                    dataList.append('files', event.target.files[0]);
                    dataList.append('name', event.target.files[0].name);
                    this.setState({ files: fileList });
                    this.setState({ data: dataList })
                }
              
            }
          
            //this.setState({
            //fileList: event.target.files[0],
            //})
            //let dataList = new FormData()

            //for (var i = 0; i < event.target.files.length; i++) {
            //    dataList.append('files', event.target.files[i]);
            //    dataList.append('name', event.target.files[i].name);
            //}

        }

        //const ClickOnAddFileBtn = () => {
        //    if (this.state.RevID > 0) {
        //        this.setState({
        //            addFileModalShow: true,
        //        });
        //    }
        //    else {
        //        alert("Please Submit Request details before add attachments files");
        //    }

        //}
        const fndownloadFile = (fileType, attachmentID, fileName, fileData) => {

            const linkSource = `data:${fileType};base64,${fileData}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
        const fndeleteFile = (attachmentID) => {
            if (window.confirm('Are you sure?')) {
                this.setState({ isActive: true });
                fetch('DmcFiles/Delete/' + attachmentID, {
                    method: 'DELETE',
                    header: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                    .then((result) => {
                        this.setState({
                            Msg: result,
                           isActive: false
                        });
                        this.AttachmentList();
                        setTimeout(() => {
                            this.setState({
                                Msg: '',
                                isActive: false
                            });
                        }, 1000)
                       
                    },
                        (error) => {
                            alert('Failed');
                            this.setState({
                                Msg: '',
                                isActive: false
                            });
                        })
            }
        }

        return (
            <>
            <div style={{ width: "100%" }} className={this.state.isActive == true ? 'loading' : ''}>
            <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ width: "62%" }}>
                        <span className="Msg" >{this.state.Msg}</span>
                    <Table className="mt-4" striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "5%", display: this.props.disabled ? "none" : "" }}>Action</th>
                                <th style={{ width: "5%" }}>Download</th>
                                <th>FileName</th>
                                <th>FileType</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.fileLists.map(file =>
                                <tr key={file.attachmentID}>
                                    <td style={{ display: this.props.disabled ? "none" : "" }}>
                                        <ButtonToolbar >
                                            <div style={{ width: "100%", display: "flex" }}>
                                                <div style={{ width: "50%" }}>
                                                    <Button className="mr-1" variant="danger" disabled={this.props.disabled} onClick={() => fndeleteFile(file.attachmentID)}>
                                                        <FaTrash />
                                                    </Button>
                                                </div>
                                            </div>
                                        </ButtonToolbar>

                                    </td>
                                    <td>
                                        <ButtonToolbar>
                                            <Button className="mr-1" variant="download"
                                                onClick={() => fndownloadFile(file.fileType, file.attachmentID, file.fileName, file.fileData)}>
                                                <FaArrowCircleDown />
                                            </Button>
                                        </ButtonToolbar>
                                    </td>
                                    <td>{file.fileName}</td>
                                    <td>{file.fileType}</td>
                                </tr>)
                            }
                        </tbody>

                    </Table>
                </div>
                <div style={{ width: "5%" }}>
                </div>
                        <div style={{ width: "33%", alignContent: "center", display: this.props.disabled ? "none" : "" }}>
                    <div style={{ width: "100%" }}>
                        <div style={{ width: "100%" }}>
                            <DragAndDrop handleDrop={handleDrop}>
                                <div class="drag-area">
                                    <div > <label for="upload" class="icon" ><FaCloudUploadAlt /></label></div>
                                    <header>Drag & Drop to Upload Files</header>
                                    <span>OR</span>
                                    <label for="upload" className="button" >Upload file</label>

                                    <input type="file" id="upload" onChange={handleInputChange} hidden />
                                </div>
                            </DragAndDrop>
                        </div>
                        <div style={{ display: "flex", width: "100%", textAlign: "left", marginTop: "5px", marginLeft: "8px" }}>
                            <div style={{ width: "65%" }}>
                                {this.state.files != "" ?

                                    this.state.files.map((file) =>
                                        <div key={file.name}>{file}</div>

                                    )

                                    :
                                    'No file uploaded'
                                }
                            </div>
                            <div style={{ width: "35%",height:"30px", display: "flex", textAlign:"right" }}>
                                <Button className="btn btn-primary btnBg"
                                        onClick={this.fnSave} disabled={this.props.disabledVal}>
                                    Add Files
                                </Button>
                                        <Button className="btn btn-primary btnBg" style={{ marginLeft: "2px", height: "30px" }}
                                    onClick={fnClear}>
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                    </div>
            </>


            //</div>
        )
    }

}

