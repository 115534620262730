import React, { Component } from 'react';
import {
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';


export class UserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = { LoginUserId: 0 }


    }
    render() {

        if (this.props.auth && this.props.auth.isAuthenticated) {
            localStorage.setItem('LoginUserID', this.props.auth.user.userName)
            try {
                fetch('Admin/CheckUserIsAdmin/' + this.props.auth.user.userName)
                    .then(response => response.json())
                    .then(data => {
                        if (data.userIsAdmin == true) {
                            localStorage.setItem('UserIsAdmin', data.userIsAdmin)
                        }
                        else {
                            localStorage.setItem('UserIsAdmin', false)
                        }

                    });
            } catch (error) {
                localStorage.setItem('UserIsAdmin', false)
                alert(error);
            }

            return (
                <UncontrolledDropdown style={{ color: "white" }}>
                    <DropdownToggle nav caret>
                        {this.props.auth.user.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <h5 className="dropdown-item-text mb-0">{this.props.auth.user.name}</h5>
                        <p className="dropdown-item-text text-muted mb-0">{this.props.auth.user.userName}</p>
                        <DropdownItem divider />
                        <DropdownItem onClick={this.props.onSignOut}>Sign Out</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                
            );
        }
        else {
            return (/*<NavItem>*/
                <NavLink  onClick={this.props.onSignIn} href='#'>Sign in</NavLink>
            /*</NavItem>*/);
        }
    }
}
