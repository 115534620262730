import React, { Component } from 'react';
import { FaPlus, FaDownload, FaPrint, FaSpinner } from "react-icons/fa";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Button, Form, ButtonToolbar } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap-accordion'
import 'react-bootstrap-accordion/dist/index.css'
import { AddDrawing } from '../Dashboard/AddDrawing';
import { FileList } from '../../pages/File/FileList';
import '../Dashboard/Dashboard.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle'


export class NewRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DeptLists: [], AllUsers: [], AffDepts: [], filteredSuggestions: [], filteredSuggestionsRevisedBy: [], UserName: props.location.state.LoginUserName, ExistsLoginUserMailId: props.location.state.ExistsLoginUserMailId,
            UserID: props.location.state.LoginUserID, ProPosedByEmailID: props.location.state.ExistsLoginUserMailId, RevID: props.location.state.RevID, Msg: '', startDate: new Date(), EditDeptID: 0, EditReason: '', ExistsLoginRoleName: props.location.state.ExistsLoginRoleName,
            EditGA: '', EditUrgent: '', EditStchg: '', addModalShow: false, editModalShow: false, addFileModalShow: false, LoginRoleName: props.location.state.LoginRoleName,
            ApprlByArexAdmin: [], ApprlByAdmin: [], ReqInput: '', ProjectDeliveryListsOne: [], ProjectDeliveryListsTwo: [],
            RequirementList: [], OutEvidentList: [], OutValidEvidentList: [], RevisionDate: new Date(), /*StatusList: [],*/
            RevisedBy: '', RevisedByID: 0, EditBusinessSegment: '', EditProductFamily: '', EditProductModel: '',
            EditInternalContainmentPlan: '', EditFieldContainmentPlan: '', EditContComment: '', EditRevNumber: '', EditRevComments: '',
            EditStatusID: '', TabTitle: 'New Request', btnsubmit: 'Save', LoginUserMailID: localStorage.getItem("LoginUserID"), DisableVal: false
            , Stchgs: ["No"], UrgentLists: ["IMMEDIATE (Production On Hold Until Implementation)", "Change For Future Production"]
            , InternalPlanList: [], FieldPlanList: []
            , BusinessSegmentList: [], ProductFamilyList: [], ApprlByArexAdminVal: "", ApprlByAdminVal: "", RequestedDate: null, IsRequestApprovedEmailSent: 0, IsRequestCompletedEmailSent: 0
            , IsApprovedByAdmin: false, IsApprovedByAtex: false, isActive: true, IsAtexRequired: '', FileCopy: '', AutoGenID: 0, StatusDRRComplete: false, BtnName: '', GlobalChange: '', PrintReportType: 'Request'
            , GRevisionDate: new Date(), GRevisionBy: '', GRevisionByID: 0, GRevisionNotes: '', GRevisionReqReset: false, FinalApprlByArexAdmin: [], FinalApprlByArexAdminVal:""
            , IsApprovedByAdminAndAtex: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitNewRequest = this.handleSubmitNewRequest.bind(this);
    }




    componentDidMount() {
        if (this.state.RevID > 0) {
            if (this.state.ExistsLoginUserMailId.toLowerCase() != this.state.LoginUserMailID.toLowerCase() && this.state.LoginRoleName.toLowerCase() == "users") {
                this.setState({ DisableVal: true });
            }
            else if (this.state.ExistsLoginUserMailId.toLowerCase() == this.state.LoginUserMailID.toLowerCase() && this.state.LoginRoleName.toLowerCase().indexOf("users") > -1 && this.state.RequestedDate == null) {
                this.setState({ DisableVal: false });
            }

            else if (this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1) {
                this.setState({ DisableVal: false });
            }
            else if (this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("Approval") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1) {
                this.setState({ DisableVal: true });
            }

        }
        else {
            if (this.state.LoginRoleName.toLowerCase().indexOf("users") > -1) {
                this.setState({ DisableVal: false });
            }
            else {
                this.setState({ DisableVal: true });
            }
        }

        this.GetAllDetails();

    }

    handleChange = (date) => {
        this.setState({
            startDate: date
        })

    }
    revisionHandleChange = (date) => {
        this.setState({
            RevisionDate: date
        })

    }
    gRevisionHandleChange = (date) => {
        this.setState({
            GRevisionDate: date
        })

    }
    RequestDownloadPDF = () => {
        this.setState({
            isActive: true
        });
        fetch('DashBoard/RequestDownloadPDF/' + this.state.RevID)
            .then(response => response.json())
            .then(data => {
                const linkSource = `data:${data.fileType};base64,${data.binary}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = data.fileName;
                downloadLink.click();
                this.setState({
                    isActive: false
                });
            });


    }
    CompRequestDownloadPDF = () => {
        this.setState({
            isActive: true
        });
        fetch('DashBoard/CompRequestDownloadPDF/' + this.state.RevID)
            .then(response => response.json())
            .then(data => {
                const linkSource = `data:${data.fileType};base64,${data.binary}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = data.fileName;
                downloadLink.click();
                this.setState({
                    isActive: false
                });
            });


    }


    handleSubmitNewRequest(event) {
        event.preventDefault();
        var TotDrwCount = 0;

        if (localStorage.getItem("CheckDrawing") == "1") {
            var RequirementValues = "";
            var CheckRequirementValues = "";
            for (const req of this.state.RequirementList) {
                const checkBox = document.querySelector('input[name=Chkreq' + req.dmcRequirementId + ']');
                const InputReq = document.querySelector('input[name=InputReq' + req.dmcRequirementId + ']');
                const VerifOutEv = document.querySelector('select[name=VerifOutEv' + req.dmcRequirementId + ']');
                const VerifSupEv = document.querySelector('textarea[name=VerifSupEv' + req.dmcRequirementId + ']');
                const ValidOutEv = document.querySelector('select[name=ValidOutEv' + req.dmcRequirementId + ']');
                const ValidSupEv = document.querySelector('textarea[name=ValidSupEv' + req.dmcRequirementId + ']');
                if (checkBox.checked == true) {
                    if (InputReq.value != "" && VerifOutEv.value != "0" && ValidOutEv.value != "0") {
                        if (CheckRequirementValues == "") {
                            CheckRequirementValues = "true"
                        }
                    }
                    else {
                        CheckRequirementValues = "false"
                    }
                    RequirementValues = RequirementValues + "^" + req.dmcRequirementId + "!" + InputReq.value + "!" + VerifOutEv.value + "!" + VerifSupEv.value + "!" + ValidOutEv.value + "!" + ValidSupEv.value + "!" + req.dmcDrrRequirementId + "!" + true;
                }
                else {
                    RequirementValues = RequirementValues + "^" + req.dmcRequirementId + "!" + InputReq.value + "!" + VerifOutEv.value + "!" + VerifSupEv.value + "!" + ValidOutEv.value + "!" + ValidSupEv.value + "!" + req.dmcDrrRequirementId + "!" + false;
                }
            }
            var ProjectDeliveryListsOne = "";
            var CheckProjectDeliveryListsOne = "false";
            for (const pro of this.state.ProjectDeliveryListsOne) {
                const checkBox = document.querySelector('input[name=ProOne' + pro.dmcProjectDeliverableId + ']');
                var Response = "No"
                if (checkBox.checked == true) {
                    Response = "Yes"
                    CheckProjectDeliveryListsOne = "true"
                }
                ProjectDeliveryListsOne = ProjectDeliveryListsOne + "^" + pro.dmcDrrProjectDeliverableId + "!" + pro.dmcProjectDeliverableId + "!" + Response;

            }

            if ((this.state.IsApprovedByAdminAndAtex == true && (CheckProjectDeliveryListsOne == "true" && (this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1) && event.target.RevisionDate.value != "" && event.target.RevNoID.value != "" && this.state.RevisedByID > 0)) || ((this.state.LoginRoleName.toLowerCase().indexOf("reviewer") == -1 && this.state.LoginRoleName.toLowerCase().indexOf("revision") == -1) || this.state.IsApprovedByAdminAndAtex == false)) {
                if (((this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1) && this.state.IsApprovedByAdminAndAtex == true && this.state.EditStchg == "Yes" && event.target.BusinessSegmentID.value != "" && event.target.ProductFamilyID.value != "" && event.target.ProductModelID.value != "" && CheckRequirementValues == "true") || (this.state.EditStchg == "No" || this.state.IsApprovedByAdminAndAtex == false || (this.state.LoginRoleName.toLowerCase().indexOf("revision") == -1 && this.state.LoginRoleName.toLowerCase().indexOf("reviewer") == -1))) {
                    this.setState({ isActive: true })
                    if (this.state.UserID > 0) {
                        var selectedvals = "";
                        for (const AffDept of this.state.AffDepts) {
                            const radioButtons = document.querySelectorAll('input[name="' + AffDept.dmcNotificationRoleId + '"]');
                            for (const radioButton of radioButtons) {
                                if (radioButton.checked) {
                                    selectedvals = selectedvals + "^" + AffDept.dmcNotificationRoleId + ":" + radioButton.value + ":" + AffDept.dmcAffectedDeptId;
                                    break;
                                }
                            }
                        }
                        var ProjectDeliveryListsTwo = "";
                        for (const pro of this.state.ProjectDeliveryListsTwo) {
                            const checkBox = document.querySelector('input[name=ProTwo' + pro.dmcNotificationRoleId + ']');
                            var Response = "No"
                            if (checkBox.checked == true) {
                                Response = "Yes"
                            }
                            ProjectDeliveryListsTwo = ProjectDeliveryListsTwo + "^" + pro.dmcDrrFinalNotificationId + "!" + pro.dmcNotificationRoleId + "!" + Response;


                        }
                        var StatusDRRComplete = 1;
                        if (event.target.ApprlByAdmin.value === "Rejected" || event.target.ApprlByArexAdmin.value === "Rejected") {
                            StatusDRRComplete = 5;
                        }
                        else if (event.target.FinalAtexApproval.value === "Rejected") {
                            StatusDRRComplete = 7;
                        }
                        else if (this.state.StatusDRRComplete === true) {
                            StatusDRRComplete = 4;
                        }
                        else if (event.target.FinalAtexApproval.value === "Approved") {
                            StatusDRRComplete = 6;
                        }
                        else if (this.state.IsApprovedByAdminAndAtex == true && (this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1) && event.target.InterContPlanID.value != "" && event.target.FieldContPlanID.value != "") {
                            StatusDRRComplete = 3;
                        }
                        else if ((event.target.ApprlByAdmin.value === "Approved" && event.target.IsAtexRequired.value.toLowerCase() === "no") || (event.target.IsAtexRequired.value.toLowerCase() == "yes" && event.target.ApprlByArexAdmin.value === "Approved")) {
                            StatusDRRComplete = 2;
                        }
                       


                        fetch('DashBoard/AddUpdateRequest', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                DmcLoginUserMailId: this.state.LoginUserMailID,
                                DmcDrawingRevId: parseInt(this.state.RevID),
                                DmcUserId: parseInt(this.state.UserID),
                                ProposedByDate: "" + event.target.ProposedByDate.value + "",
                                DmcDepartmentId: parseInt(event.target.DeptProposingid.value),
                                Reason: "" + event.target.Reason.value + "",
                                Garef: "" + event.target.GARef.value + "",
                                StructuralChange: "" + event.target.StructuralChange.value + "",
                                Urgency: "" + event.target.Urgency.value + "",
                                AffDepts: "" + selectedvals + "",
                                AdminApproval: "" + event.target.ApprlByAdmin.value + "",
                                AtexAppproval: "" + event.target.ApprlByArexAdmin.value + "",
                                BusinessSegment: "" + event.target.BusinessSegmentID.value + "",
                                ProductFamily: "" + event.target.ProductFamilyID.value + "",
                                ProductModel: "" + event.target.ProductModelID.value + "",
                                InternalContainmentPlan: "" + event.target.InterContPlanID.value + "",
                                FieldContainmentPlan: "" + event.target.FieldContPlanID.value + "",
                                ContComment: "" + event.target.ContCommentID.value + "",
                                DmcDrrRequirement: "" + RequirementValues + "",
                                DmcDrrProjectDeliverable: "" + ProjectDeliveryListsOne + "",
                                DmcDrrFinalNotification: "" + ProjectDeliveryListsTwo + "",
                                StrRevisionDate: "" + event.target.RevisionDate.value + "",
                                RevisedByID: parseInt(this.state.RevisedByID),
                                RevNumber: "" + event.target.RevNoID.value + "",
                                RevComments: "" + event.target.RevisionCommentID.value + "",
                                DmcDrrstatusId: parseInt(StatusDRRComplete),
                                IsAtexRequired: "" + event.target.IsAtexRequired.value + "",
                                FileCopy: "" + event.target.FileCopy.value + "",
                                GlobalChange: "" + event.target.GlobalChange.value + "",
                                AutoGenId: parseInt(this.state.AutoGenID),
                                BtnClickName: "" + this.state.BtnName + "",
                                StrGRevisionDate: "" + event.target.GRevisionDate.value + "",
                                GRevisionNotes: "" + event.target.GRevisionNotes.value + "",
                                GRevisionByID: parseInt(this.state.GRevisionByID) ,
                                GRevisionReqReset: event.target.GRevisionReqReset.checked,
                                FinalAtexApproval: "" + event.target.FinalAtexApproval.value + "",
                                




                            })
                        })
                            .then(res => res.json())
                            .then((result) => {
                                localStorage.setItem('UpdateDrawing', "yes")
                                this.setState({
                                    filteredSuggestions: [],
                                    filteredSuggestionsRevisedBy: [],
                                    Msg: result.msg,
                                    RevID: result.revID,
                                    AutoGenID: 0
                                });
                                this.SendSubmottedWorkFlowEmail(event, this.state.RevID, this.state.AffDepts, this.state.ProjectDeliveryListsTwo, StatusDRRComplete, this.state.RequestedDate);

                                this.GetAllDetails();
                                setTimeout(() => {
                                    this.setState({
                                        Msg: ''
                                    });
                                }, 10000)
                            },
                                (error) => {
                                    this.setState({
                                        isActive: false
                                    });
                                    alert('Failed' + ': ' + error);

                                })
                    }
                    else {
                        this.setState({
                            isActive: false
                        });
                        alert("UserName is not valid")
                        return false;
                    }
                }
                else {
                    if (event.target.BusinessSegmentID.value == "" || event.target.ProductFamilyID.value == "" || event.target.ProductModelID.value == "") {
                        alert("Because structural is set to yes, These fields are mandatory Business Segment, Product Family, and Product Model")
                    }
                    else if (CheckRequirementValues == "false" || CheckRequirementValues == "") {
                        alert("Because structural is set to yes, Please mark at least one checkbox in the Requirement Section and enter a value for Design Input, Verification, and Validation.");
                    }

                }
            }
            else {

                if (CheckProjectDeliveryListsOne == "false") {
                    alert("In Project Deliverables Section: (Please select at least one checkbox from the list).");

                }
                else if (event.target.RevisionDate.value == "" || event.target.RevNoID.value == "" || this.state.RevisedByID == 0) {
                    alert("In Production Design Section: (Please enter a value for Revision Date, Revised By, and Rev No Plan).");
                }

            }
        }
        else {
            alert("At least one drawing must be added before submitting this form.");
        }
    }



    render() {
        const { drawingID, dwgNumber, atex, partNumber, fileCopy, description } = this.state;
        let addModalClose = () => this.setState({ addModalShow: false });
        let editModalClose = () => this.setState({ editModalShow: false });
        let addFileModalClose = () => this.setState({ addFileModalShow: false });

        const onChangeHandler = (UserName) => {
            let filteredSuggestions = []
            if (UserName.length > 0) {
                filteredSuggestions = this.state.AllUsers.filter(usr => {
                    return usr.dmcUserName.toLowerCase().indexOf(UserName.toLowerCase()) > -1
                });
            }
            this.setState({
                filteredSuggestions: filteredSuggestions,
                UserName: UserName
            });
        }
        const onSuggestionHandler = (UserName, UserID, UserEmailID) => {
            this.setState({
                filteredSuggestions: [],
                UserName: UserName,
                UserID: UserID,
                ProPosedByEmailID: UserEmailID
            });
        }
        const onChangeHandlerRevisedBy = (UserName) => {

            let filteredSuggestionsRevisedBy = []
            if (UserName.length > 0) {
                filteredSuggestionsRevisedBy = this.state.AllUsers.filter(usr => {
                    return usr.dmcUserName.toLowerCase().indexOf(UserName.toLowerCase()) > -1
                });
            }
            else {
                this.setState({
                    RevisedByID: 0
                });
            }
            this.setState({
                filteredSuggestionsRevisedBy: filteredSuggestionsRevisedBy,
                RevisedBy: UserName
            });
        }
        const onSuggestionHandlerRevisedBy = (UserName, UserID) => {
            this.setState({
                filteredSuggestionsRevisedBy: [],
                RevisedBy: UserName,
                RevisedByID: UserID
            });
        }
        const onChangeHandlerGRevisedBy = (UserName) => {

            let filteredSuggestionsRevisedBy = []
            if (UserName.length > 0) {
                filteredSuggestionsRevisedBy = this.state.AllUsers.filter(usr => {
                    return usr.dmcUserName.toLowerCase().indexOf(UserName.toLowerCase()) > -1
                });
            }
            else {
                this.setState({
                    GRevisionByID: 0
                });
            }
            this.setState({
                filteredSuggestionsRevisedBy: filteredSuggestionsRevisedBy,
                GRevisionBy: UserName
            });
        }
        const onSuggestionHandlerGRevisedBy = (UserName, UserID) => {
            this.setState({
                filteredSuggestionsRevisedBy: [],
                GRevisionBy: UserName,
                GRevisionByID: UserID
            });
        }


        const ClickOnAddDrawingBtn = () => {
            if (this.state.RevID > 0 || this.state.AutoGenID > 0) {
                this.setState({
                    addModalShow: true,
                });
            }
            else {
                alert("Please Submit Request details before add drawing");
            }

        }

        const onChangeReqCheckbox = (checkBox, id) => {
            if (checkBox == true) {
                this.setState({
                    ReqInput: this.state.ReqInput + "DisableReq" + id + "^"
                });
            }
            else {
                var str = this.state.ReqInput.replace("DisableReq" + id + "^")
                if (str == "undefined") {
                    str = "";
                }
                this.setState({
                    ReqInput: str
                });
            }

        }


        const myComponent = {
            /*  width: '500px',*/
            /*height: '550px',*/
            overflowX: 'hidden',
            overflowY: 'auto',
            backgroundColor: 'white',
            marginBottom: '50px'


        };

        const onStructuralChangeHandler = (Val) => {
            this.setState({
                EditStchg: Val,
            });
        }
        const focusout = () => {
            this.setState({
                filteredSuggestionsRevisedBy: []
            });

        }
        const StatusDRRComplete = (Val) => {
            this.setState({
                StatusDRRComplete: Val
            });

        }
        const ApprlByAdmin = (Val) => {
            if ((Val === "Rejected" || Val == "None") && this.state.ApprlByAdminVal === "Approved" && this.state.IsApprovedByAdminAndAtex === true) {
                this.setState({
                    IsApprovedByAdminAndAtex: false
                });
            }
            else if (Val === "Approved" && this.state.ApprlByAdminVal === "Approved" && this.state.IsApprovedByAdminAndAtex === false) {
                this.setState({
                    IsApprovedByAdminAndAtex: true
                });
            }
        }

        const IsAtexReq = (Val) => {
            if (this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1) {
                if (Val == "Yes" && this.state.IsApprovedByAdminAndAtex == true && this.state.ApprlByArexAdminVal != "Approved") {
                    this.setState({
                        IsApprovedByAdminAndAtex: false
                    });
                }
                else if (Val == "No" && this.state.IsApprovedByAdminAndAtex == false && this.state.ApprlByAdminVal == "Approved") {
                    this.setState({
                        IsApprovedByAdminAndAtex: true
                    });
                }
            }

        }
        const setButtonValue = (buttonValue) => {
            this.setState({
                BtnName: buttonValue
            });
        }
        const handlePrintChange = (ValType) => {
            if (ValType == "Request") {
                this.setState({
                    PrintReportType: ValType,
                });
            }
            else if (ValType == "All") {
                this.setState({
                    PrintReportType: ValType,
                });
            }


        }
        const PrintReport = () => {
            if (this.state.PrintReportType == "Request") {
                this.RequestDownloadPDF();
            }
            else if (this.state.PrintReportType == "All") {
                this.CompRequestDownloadPDF();

            }

        }

        return (

            <>
                <div className={this.state.isActive == true ? 'loading' : ''}>

                    <Form onSubmit={this.handleSubmitNewRequest}>
                        <Form.Group >

                            <div style={{ width: "100%", textAlign: "right", padding: "0px", backgroundColor: "white", display: "flex" }} >
                                <div style={{ width: "50%", textAlign: "left" }}  >
                                    <div style={{ display: this.state.RevID > 0 ? "block" : "none" }} > <span style={{ fontSize: "18px", fontWeight: "bold" }}>DRR# {this.state.RevID}</span> </div>
                                </div>
                                <div style={{ width: "50%", textAlign: "right" }} >

                                    <Button style={{ backgroundColor: "black", color: "white", padding: "0.200rem 0.50rem" }} onClick={() => setButtonValue('save')} type="submit" disabled={this.state.ExistsLoginUserMailId.toLowerCase() != this.state.LoginUserMailID.toLowerCase() && this.state.LoginRoleName.toLowerCase() == "users" && this.state.RevID > 0 && this.state.ExistsLoginUserMailId != "" ? true : false} >
                                        {this.state.btnsubmit}
                                    </Button>
                                    <span style={{ padding: "2px" }}></span>
                                    <Button style={{ backgroundColor: "black", color: "white", padding: "0.200rem 0.50rem" }} onClick={() => setButtonValue('submit')} type="submit" title="Final Request Submit" disabled={this.state.ExistsLoginUserMailId.toLowerCase() != this.state.LoginUserMailID.toLowerCase() && this.state.LoginRoleName.toLowerCase() == "users" && this.state.RevID > 0 && this.state.ExistsLoginUserMailId != "" ? true : this.state.RequestedDate != null ? true : false}>
                                        Submit
                                    </Button>
                                    <span style={{ padding: "2px" }}></span>
                                    <Link to={{
                                        pathname: '/',

                                    }}>
                                        <Button style={{ backgroundColor: "black", color: "white", padding: "0.200rem 0.50rem" }}>
                                            Back
                                        </Button>
                                    </Link>

                                </div>
                            </div>
                        </Form.Group>
                        <div >
                            <Tabs
                                defaultActiveKey="Request"
                                id="uncontrolled-tab-example"
                                className="mb-4"

                            >
                                <Tab eventKey="Request" title="Request" >
                                    <div className='App' style={myComponent}>
                                        <div style={{ width: "100%", backgroundColor: "white", padding: "0px 0px 5px 0px", display: "flex" }} >
                                            <div style={{ width: "92%", textAlign: "center", backgroundColor: "white" }} >
                                                <span className="Msg" >{this.state.Msg}</span>
                                            </div>
                                            <div style={{ width: "18%", textAlign: "right", marginRight: "8px", display: (this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("Approval") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1) && this.state.RevID > 0 ? "block" : "none" }} >
                                                <span style={{ fontSize: "16px", fontWeight: "bold", marginRight: "12px", cursor: "pointer" }} onClick={() => PrintReport()} ><FaDownload />&nbsp;Report</span>
                                                <span><select style={{ fontSize: "13px", fontWeight: "bold", cursor: "pointer" }} onChange={(e) => handlePrintChange(e.target.value)}><option value="Request">Print Request</option ><option value="All">Print All</option></select></span>
                                            </div>
                                        </div>
                                        <Accordion title={this.state.TabTitle} show={true}  >
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="ProposedByID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Proposed By</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Control type="text" name="ProposedByID" disabled={this.state.DisableVal}
                                                                    placeholder="ProposedBy" onChange={e => onChangeHandler(e.target.value)} value={this.state.UserName} required />
                                                                <div className="UserAutoCompletediv">
                                                                    {this.state.filteredSuggestions && this.state.filteredSuggestions.map((Suggestions, i) =>
                                                                        <div key={i} className="UserAutoComplete" onClick={() => onSuggestionHandler(Suggestions.dmcUserName, Suggestions.dmcUserId, Suggestions.dmcUserEmail)}> {Suggestions.dmcUserName} </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Form.Group>

                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="ProposedByDate" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Proposed By Date</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <DatePicker
                                                                    selected={this.state.startDate}
                                                                    onChange={this.handleChange} disabled={this.state.DisableVal} required
                                                                    name="startDate"
                                                                    dateFormat="MM/dd/yyyy" className="form-control" name="ProposedByDate" placeholder="Proposed By Date"
                                                                />

                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="DeptProposingid" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Dept Proposing</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="DeptProposingid" disabled={this.state.DisableVal} required>
                                                                    <option value={''}  >{'Select Dept Proposing'}</option>
                                                                    {this.state.DeptLists.map(Dept =>
                                                                        Dept.dmcDepartmentId == this.state.EditDeptID ?
                                                                            <option value={Dept.dmcDepartmentId} selected>{Dept.homeCode2}</option>
                                                                            :
                                                                            <option value={Dept.dmcDepartmentId}>{Dept.homeCode2}</option>


                                                                    )}
                                                                </Form.Select>

                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="Urgency" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Urgency</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="Urgency" disabled={this.state.DisableVal} required>
                                                                    <option value={''} >{'Select Urgency'}</option>
                                                                    {this.state.UrgentLists.map(UrgentList =>
                                                                        UrgentList == this.state.EditUrgent ?
                                                                            <option value={UrgentList} selected>{UrgentList}</option>
                                                                            :
                                                                            <option value={UrgentList}>{UrgentList}</option>


                                                                    )}
                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        {this.state.EditStchg && this.state.Stchgs.map(AffDept =>
                                                            <Form.Group controlId="StructuralChange" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Structural Change</Form.Label>
                                                                </div>
                                                                <div style={{ width: "60%" }}>
                                                                    <div style={{ width: "100%", display: "flex", fontSize: "18px" }}>
                                                                        <div style={{ width: "3%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="Yes"
                                                                                type="radio"
                                                                                Name="StructuralChange"
                                                                                label="Yes" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.EditStchg == "Yes" ? true : false}
                                                                                onClick={() => onStructuralChangeHandler("Yes")}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "10%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="No"
                                                                                type="radio"
                                                                                Name="StructuralChange"
                                                                                label="No" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.EditStchg == "No" ? true : false}
                                                                                onClick={() => onStructuralChangeHandler("No")}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "63%" }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        {this.state.IsAtexRequired && this.state.Stchgs.map(AffDept =>
                                                            <Form.Group controlId="IsAtexRequired" style={{ display: "flex", paddingBottom: "15px" }} onChange={e => IsAtexReq(e.target.value)}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Atex</Form.Label>
                                                                </div>
                                                                <div style={{ width: "60%" }}>
                                                                    <div style={{ width: "100%", display: "flex", fontSize: "18px" }}>
                                                                        <div style={{ width: "3%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>

                                                                            <Form.Check
                                                                                value="Yes"
                                                                                type="radio"
                                                                                name="IsAtexRequired"
                                                                                label="Yes" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.IsAtexRequired == "Yes" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "10%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>

                                                                            <Form.Check
                                                                                value="No"
                                                                                type="radio"
                                                                                Name="IsAtexRequired"
                                                                                name="IsAtexRequired"
                                                                                label="No" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.IsAtexRequired == "No" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "63%" }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="GARef" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>G/A Ref</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Control type="text" name="GARef" disabled={this.state.DisableVal}
                                                                    placeholder="G/A Ref" defaultValue={this.state.EditGA} required />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        {this.state.FileCopy && this.state.Stchgs.map(AffDept =>
                                                            <Form.Group controlId="FileCopy" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>FileCopy</Form.Label>
                                                                </div>
                                                                <div style={{ width: "60%" }}>
                                                                    <div style={{ width: "100%", display: "flex", fontSize: "18px" }}>
                                                                        <div style={{ width: "3%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="Yes"
                                                                                type="radio"
                                                                                Name="FileCopy"
                                                                                label="Yes" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.FileCopy == "Yes" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "10%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="No"
                                                                                type="radio"
                                                                                Name="FileCopy"
                                                                                label="No" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.FileCopy == "No" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "63%" }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}

                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="Reason" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Reason</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Control type="text" name="Reason" as="textarea" disabled={this.state.DisableVal}
                                                                    placeholder="Reason" defaultValue={this.state.EditReason} required />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        {this.state.GlobalChange && this.state.Stchgs.map(AffDept =>
                                                            <Form.Group controlId="GlobalChange" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Global Change</Form.Label>
                                                                </div>
                                                                <div style={{ width: "60%" }}>
                                                                    <div style={{ width: "100%", display: "flex", fontSize: "18px" }}>
                                                                        <div style={{ width: "3%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="Yes"
                                                                                type="radio"
                                                                                Name="GlobalChange"
                                                                                label="Yes" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.GlobalChange == "Yes" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "10%" }}>
                                                                        </div>
                                                                        <div style={{ width: "12%" }}>
                                                                            <Form.Check
                                                                                value="No"
                                                                                type="radio"
                                                                                Name="GlobalChange"
                                                                                label="No" disabled={this.state.DisableVal}
                                                                                defaultChecked={this.state.GlobalChange == "No" ? true : false}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div style={{ width: "63%" }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>

                                        </Accordion>
                                        <Accordion title="Drawing" style={{ width: "80%" }} show={true} >
                                            <div style={{ width: "80%" }} >
                                                <div style={{ width: "100%" }}>
                                                    <ButtonToolbar>
                                                        <Button className="btnadd btn-sm btn-outline-dark ReadOnlyCS"
                                                            onClick={() => ClickOnAddDrawingBtn()} disabled={this.state.DisableVal}>
                                                            <FaPlus /> Add Drawing</Button>

                                                        <AddDrawing revID={this.state.RevID} autoGenID={this.state.AutoGenID} drawingID="0" disabled={this.state.DisableVal} show={this.state.addModalShow}
                                                            onHide={addModalClose} />
                                                    </ButtonToolbar>
                                                </div>

                                            </div>
                                        </Accordion>
                                        <Accordion title="Attachment" show={true} >
                                            <div style={{ width: "80%" }} >
                                                <FileList RevID={this.state.RevID} disabled={this.state.DisableVal} />

                                            </div>

                                        </Accordion>
                                        <Accordion title="Affected Depts">
                                            {this.state.AffDepts && this.state.AffDepts.map((AffDept, i) =>
                                                <Form.Group controlId={AffDept.dmcNotificationRoleId} style={{ display: "flex", paddingBottom: "15px" }}>
                                                    <div style={{ width: "10%", display: "flex" }}>
                                                        <div style={{ width: "25%" }}>
                                                            <Form.Check
                                                                value="Yes"
                                                                type="radio"
                                                                Name={AffDept.dmcNotificationRoleId}
                                                                label="Yes" disabled={this.state.DisableVal == true ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase() == "users" && AffDept.dmcNotificationName == "CPI(ATEX Only)" ?
                                                                        true
                                                                        :
                                                                        false}
                                                                defaultChecked={AffDept.reason == "Yes" ? true : false}
                                                            />
                                                        </div>
                                                        <div style={{ width: "20%" }}>
                                                        </div>
                                                        <div style={{ width: "25%" }}>
                                                            <Form.Check
                                                                value="No"
                                                                type="radio"
                                                                Name={AffDept.dmcNotificationRoleId}
                                                                label="No" disabled={this.state.DisableVal == true ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase() == "users" && AffDept.dmcNotificationName == "CPI(ATEX Only)" ?
                                                                        true
                                                                        :
                                                                        false}
                                                                defaultChecked={AffDept.reason == "No" ? true : false}

                                                            />
                                                        </div>
                                                        <div style={{ width: "30%" }}>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "92%" }}>
                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>{AffDept.dmcNotificationName}</Form.Label>
                                                    </div>
                                                </Form.Group>

                                            )}
                                        </Accordion>
                                       
                                        <div style={{
                                            display:
                                                this.state.LoginRoleName.toLowerCase() == "users" || this.state.LoginRoleName.toLowerCase() == "dept mgrs" ?
                                                    "none"
                                                    :
                                                    "block"
                                        }}>



                                            <Accordion title="Admin Approval">
                                                <div>
                                                    <div style={{ textAlign: "left", fontWeight: "bold" }}>REQUEST APPROVAL (edit only by Approval Admin)</div>
                                                    <div>
                                                        {this.state.ApprlByAdmin && this.state.ApprlByAdmin.map((str, i) =>
                                                            <Form.Group controlId={str.id} style={{ display: "flex", paddingBottom: "10px", paddingTop: "10px" }} onChange={e => ApprlByAdmin(e.target.value)}>
                                                                <div style={{ width: "10%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Approval Status</Form.Label>
                                                                </div>
                                                                <div style={{ width: "90%", display: "flex", textAlign: "left" }}>
                                                                    <div style={{ width: "10%" }}>
                                                                        <Form.Check
                                                                            value="Approved"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="Approved" disabled={this.state.LoginRoleName.toLowerCase().indexOf("approval") > -1 && this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            defaultChecked={str.val == "Approved" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "10%" }}>
                                                                        <Form.Check
                                                                            value="Rejected"
                                                                            type="radio"
                                                                            name={str.id}

                                                                            label="Rejected" disabled={this.state.LoginRoleName.toLowerCase().indexOf("approval") > -1 && this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            defaultChecked={str.val == "Rejected" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "80%" }}>
                                                                        <Form.Check
                                                                            value="None"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="None" disabled={this.state.LoginRoleName.toLowerCase().indexOf("approval") > -1 && this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            defaultChecked={str.val == null ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>
                                            </Accordion>
                                            <Accordion title="Atex Approval">
                                                <div>
                                                    <div style={{ textAlign: "left", fontWeight: "bold" }}>ATEX APPROVAL (edit only by Atex Admin)</div>
                                                    <div>
                                                        {this.state.ApprlByArexAdmin && this.state.ApprlByArexAdmin.map((str, i) =>
                                                            <Form.Group controlId={str.id} style={{ display: "flex", paddingBottom: "10px", paddingTop: "10px" }} >
                                                                <div style={{ width: "15%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Approval Status</Form.Label>
                                                                </div>
                                                                <div style={{ width: "80%", display: "flex", textAlign: "left" }}>
                                                                    <div style={{ width: "15%" }}>
                                                                        <Form.Check
                                                                            value="Approved"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="Approved" disabled={this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1 && this.state.IsApprovedByAdmin == true && this.state.IsAtexRequired=="Yes" && this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            defaultChecked={str.val == "Approved" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "15%" }}>
                                                                        <Form.Check
                                                                            value="Rejected"
                                                                            type="radio"
                                                                            name={str.id}

                                                                            label="Rejected" disabled={this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1 && this.state.IsApprovedByAdmin == true && this.state.IsAtexRequired == "Yes" && this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            defaultChecked={str.val == "Rejected" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ width: "80%" }}>
                                                                        <Form.Check
                                                                            value="None"
                                                                            type="radio"
                                                                            name={str.id}
                                                                            label="None" disabled={this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1 && this.state.IsApprovedByAdmin == true && this.state.IsAtexRequired == "Yes" && this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            defaultChecked={str.val == null ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>
                                            </Accordion>
                                        </div>
                                        <div style={{
                                            display:
                                                this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 ?
                                                    "block"
                                                    :
                                                    "none"
                                        }}>
                                            <Accordion title="Global Rev Section">
                                                <div style={{ display: "flex", width: "100%" }} >
                                                    <div style={{ display: "flex", width: "70%" }} >
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="GRevisionDate" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Revision Date</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%" }}>
                                                                        <DatePicker onFocus={focusout}
                                                                            selected={this.state.GRevisionDate}
                                                                            onChange={this.gRevisionHandleChange}
                                                                            disabled={this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            dateFormat="MM/dd/yyyy" className="form-control" name="GRevisionDate" placeholder="Revision Date"

                                                                        />
                                                                    </div>
                                                                </Form.Group>

                                                            </div>
                                                        </div>
                                                        <div style={{ width: "5%" }}>
                                                        </div>
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="GRevisionBy " style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Revision By</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%", position: "relative" }}>
                                                                        <Form.Control type="text" name="GRevisionBy"
                                                                            disabled={this.state.RequestedDate != null ?
                                                                                false
                                                                                :
                                                                                true}
                                                                            placeholder="Revision By" onChange={e => onChangeHandlerGRevisedBy(e.target.value)} value={this.state.GRevisionBy} />
                                                                        <div className="UserAutoCompletediv2" >
                                                                            {this.state.filteredSuggestionsRevisedBy && this.state.filteredSuggestionsRevisedBy.map((Suggestions, i) =>
                                                                                <div key={i} className="UserAutoComplete" onClick={() => onSuggestionHandlerGRevisedBy(Suggestions.dmcUserName, Suggestions.dmcUserId)}> {Suggestions.dmcUserName} </div>
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", width: "30%" }} >
                                                        <div style={{ width: "100%" }}>
                                                            <Form.Group controlId="GRevisionReqReset " style={{ display: "flex" }}>
                                                                <div style={{ width: "40%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "right" }}>Request Reset</Form.Label>
                                                                </div>
                                                                <div style={{ width: "2%" }}>
                                                                </div>
                                                                <div style={{ width: "58%" }}>
                                                                    <Form.Check
                                                                        disabled={this.state.RequestedDate != null ?
                                                                            false
                                                                            :
                                                                            true}
                                                                        type="checkbox"
                                                                        Name="GRevisionReqReset"
                                                                        label=""

                                                                    />
                                                                </div>

                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", width: "100%" }} >
                                                    <div style={{ display: "flex", width: "70%" }} >
                                                        <div style={{ width: "100%" }}>
                                                            <Form.Group controlId="GRevisionNotes" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                <div style={{ width: "18%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Rev Notes</Form.Label>
                                                                </div>
                                                                <div style={{ width: "82%" }}>
                                                                    <Form.Control type="text" name="GRevisionNotes" as="textarea"
                                                                        disabled={this.state.RequestedDate != null ?
                                                                            false
                                                                            :
                                                                            true}
                                                                        placeholder="Revision Notes" defaultValue={this.state.GRevisionNotes} />
                                                                </div>
                                                            </Form.Group>

                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", width: "30%" }} >
                                                    </div>
                                                </div>
                                            </Accordion>
                                        </div>
                                    </div>

                                </Tab>

                                <Tab eventKey="Record" title="Record" disabled={(this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1) && this.state.RequestedDate != null ?
                                    false
                                    :
                                    true}>
                                    <div className='App'>
                                        <div style={{ width: "100%", backgroundColor: "white", padding: "0px 0px 5px 0px", display: "flex" }} >
                                            <div style={{ width: "92%", textAlign: "center", backgroundColor: "white" }} >
                                                <span className="Msg" >{this.state.Msg}</span>
                                            </div>
                                            <div style={{ width: "18%", textAlign: "right", marginRight: "8px", display: (this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("Approval") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1) && this.state.RevID > 0 ? "block" : "none" }} >
                                                <span style={{ fontSize: "16px", fontWeight: "bold", marginRight: "12px", cursor: "pointer" }} onClick={() => PrintReport()} ><FaDownload />&nbsp;Report</span>
                                                <span><select style={{ fontSize: "13px", fontWeight: "bold", cursor: "pointer" }} onChange={(e) => handlePrintChange(e.target.value)}><option value="Request">Print Request</option ><option value="All">Print All</option></select></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='App' style={myComponent}>
                                        <Accordion title="Requirement" >
                                            <div>
                                                <div style={{ border: "1px solid", padding: "10px" }}>
                                                    <div style={{ fontWeight: "bold", textAlign: "left", backgroundColor: "#D3D3D3", width: "80%" }} >
                                                        <span>*Required If Structural Change Is Equal To Yes</span>
                                                    </div>
                                                    <div style={{ display: "flex", width: "80%", marginTop: "10px" }} >
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="BusinessSegmentID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Business Segment</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%" }}>
                                                                        <Form.Select as="select" name="BusinessSegmentID"
                                                                            disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                true
                                                                                :
                                                                                this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                    false
                                                                                    :
                                                                                    true}
                                                                        >

                                                                            <option value={''}  >{'Select Business Segment'}</option>
                                                                            {this.state.BusinessSegmentList.map(Bus =>
                                                                                this.state.EditBusinessSegment == Bus.businessSegmentName ?
                                                                                    <option value={Bus.businessSegmentName} selected>{Bus.businessSegmentName}</option>
                                                                                    :
                                                                                    <option value={Bus.businessSegmentName} >{Bus.businessSegmentName}</option>


                                                                            )}

                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "5%" }}>
                                                        </div>
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="ProductFamilyID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Product Family</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%" }}>
                                                                        <Form.Select as="select" name="ProductFamilyID"
                                                                            disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                true
                                                                                :
                                                                                this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                    false
                                                                                    :
                                                                                    true}
                                                                        >
                                                                            <option value={''} >{'Select Product Family'}</option>
                                                                            {this.state.ProductFamilyList.map(Prod =>
                                                                                this.state.EditProductFamily == Prod.productFamilyName ?
                                                                                    <option value={Prod.productFamilyName} selected>{Prod.productFamilyName}</option>
                                                                                    :
                                                                                    <option value={Prod.productFamilyName} >{Prod.productFamilyName}</option>


                                                                            )}

                                                                        </Form.Select>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", width: "80%" }} >
                                                        <div style={{ width: "45%" }}>
                                                            <div style={{ width: "100%" }}>
                                                                <Form.Group controlId="ProductModelID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                                    <div style={{ width: "40%" }}>
                                                                        <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Product Model</Form.Label>
                                                                    </div>
                                                                    <div style={{ width: "60%" }}>
                                                                        <Form.Control type="text" name="ProductModelID"
                                                                            disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                true
                                                                                :
                                                                                this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                    false
                                                                                    :
                                                                                    true}
                                                                            placeholder="Product Model" defaultValue={this.state.EditProductModel} />
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "5%" }}>
                                                        </div>
                                                        <div style={{ width: "45%" }}  >
                                                        </div>
                                                    </div>

                                                </div>
                                                <div style={{ overflowY: "auto", height: "50vh", marginBottom: "10px" }}>
                                                    {this.state.RequirementList && this.state.RequirementList.map((req, i) =>
                                                        <div style={{ width: "98%" }} >
                                                            <Form.Group controlId={"req" + req.dmcRequirementId}>
                                                                <div style={{ display: "flex", width: "100%", paddingTop: "20px", paddingLeft: "20px" }}>
                                                                    <div style={{ width: "20%", fontWeight: "bold", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                        <Form.Check
                                                                            value={req.dmcRequirementId}
                                                                            type="checkbox"
                                                                            Name={"Chkreq" + req.dmcRequirementId}
                                                                            disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                true
                                                                                :
                                                                                this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                    false
                                                                                    :
                                                                                    true}
                                                                            label={req.requirementName} onChange={e => onChangeReqCheckbox(e.target.checked, req.dmcRequirementId)}
                                                                            defaultChecked={req.responce == "Yes" ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: this.state.ReqInput.indexOf("DisableReq" + req.dmcRequirementId + "^") > -1 ? "flex" : "none", width: "100%", textAlign: "left" }}>
                                                                        <div style={{ width: "25%" }}>
                                                                            <span style={{ fontWeight: "700", fontSize: "14px" }} >Design Input Requirements </span>
                                                                            <Form.Control type="text" Name={"InputReq" + req.dmcRequirementId}
                                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                    true
                                                                                    :
                                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                        false
                                                                                        :
                                                                                        true}
                                                                                placeholder="Design Input Requirements" defaultValue={req.dsignInputRequirements} />
                                                                        </div>
                                                                        <div style={{ width: "35%", paddingLeft: "30px" }}>
                                                                            <span style={{ fontWeight: "700", fontSize: "14px" }} >Verification (Design Outputs Meet Design Inputs)</span>
                                                                            <Form.Select as="select" Name={"VerifOutEv" + req.dmcRequirementId}
                                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                    true
                                                                                    :
                                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                        false
                                                                                        :
                                                                                        true}>
                                                                                <option value={0} >Select Verification Output Evidence</option>
                                                                                {this.state.OutEvidentList.map(out =>
                                                                                    req.dmcValidationOutputEvidenceId == out.dmcOutputEvidenceId && req.dmcValidationOutputEvidenceId > 0 ?
                                                                                        <option value={out.dmcOutputEvidenceId} selected>{out.evidenceName}</option>
                                                                                        :
                                                                                        <option value={out.dmcOutputEvidenceId} >{out.evidenceName}</option>


                                                                                )}

                                                                            </Form.Select>
                                                                        </div>
                                                                        <div style={{ width: "40%", paddingLeft: "30px" }}>
                                                                            <span style={{ fontWeight: "700", fontSize: "14px" }} >Validation (Design Meets Customer Application Requirements)</span>
                                                                            <Form.Select as="select" Name={"ValidOutEv" + req.dmcRequirementId}
                                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                    true
                                                                                    :
                                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                        false
                                                                                        :
                                                                                        true}>
                                                                                <option value={0} >Select validation Output Evidence</option>
                                                                                {this.state.OutValidEvidentList.map(out =>
                                                                                    req.dmcVerificationOutputEvidenceId == out.dmcOutputEvidenceId && req.dmcVerificationOutputEvidenceId > 0 ?
                                                                                        <option value={out.dmcOutputEvidenceId} selected>{out.evidenceName}</option>
                                                                                        :
                                                                                        <option value={out.dmcOutputEvidenceId} >{out.evidenceName}</option>


                                                                                )}

                                                                            </Form.Select>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: this.state.ReqInput.indexOf("DisableReq" + req.dmcRequirementId + "^") > -1 ? "flex" : "none", width: "100%", paddingTop: "20px", paddingLeft: "20px", textAlign: "left" }}>
                                                                    <div style={{ width: "20%" }}>

                                                                    </div>
                                                                    <div style={{ display: "flex", width: "100%" }}>
                                                                        <div style={{ width: "25%" }}>
                                                                        </div>
                                                                        <div style={{ width: "35%", paddingLeft: "30px" }}>
                                                                            <Form.Control type="text" Name={"VerifSupEv" + req.dmcRequirementId} as="textarea"

                                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                    true
                                                                                    :
                                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                        false
                                                                                        :
                                                                                        true}
                                                                                placeholder="Verification Supporting Evidence" defaultValue={req.validationSupportingEvidence} />
                                                                        </div>
                                                                        <div style={{ width: "40%", paddingLeft: "30px" }}>
                                                                            <Form.Control type="text" Name={"ValidSupEv" + req.dmcRequirementId} as="textarea"

                                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                                    true
                                                                                    :
                                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                        false
                                                                                        :
                                                                                        true}
                                                                                placeholder="Validation Supporting Evidence" defaultValue={req.verificationSupportingEvidence} />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </Form.Group>

                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Accordion>
                                        <Accordion title="Containment Plans">
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="InterContPlanID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Internal Containment Plan</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="InterContPlanID"

                                                                    disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                        true
                                                                        :
                                                                        this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                            false
                                                                            :
                                                                            true}>
                                                                    <option value={''}  >{'Select Internal Containment Plan'}</option>
                                                                    {this.state.InternalPlanList.map(Internal =>
                                                                        this.state.EditInternalContainmentPlan == Internal.internalContainmentPlanName ?
                                                                            <option value={Internal.internalContainmentPlanName} selected>{Internal.internalContainmentPlanName}</option>
                                                                            :
                                                                            <option value={Internal.internalContainmentPlanName} >{Internal.internalContainmentPlanName}</option>


                                                                    )}

                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div style={{ width: "5%" }}>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Group controlId="FieldContPlanID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                            <div style={{ width: "40%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Field Containment Plan</Form.Label>
                                                            </div>
                                                            <div style={{ width: "60%" }}>
                                                                <Form.Select as="select" name="FieldContPlanID"

                                                                    disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                        true
                                                                        :
                                                                        this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                            false
                                                                            :
                                                                            true}>
                                                                    <option value={''}  >Select Field Containment Plan</option>
                                                                    {this.state.FieldPlanList.map(Field =>
                                                                        this.state.EditFieldContainmentPlan == Field.fieldContainmentPlanName ?
                                                                            <option value={Field.fieldContainmentPlanName} selected>{Field.fieldContainmentPlanName}</option>
                                                                            :
                                                                            <option value={Field.fieldContainmentPlanName} >{Field.fieldContainmentPlanName}</option>


                                                                    )}

                                                                </Form.Select>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%" }}>
                                                    <Form.Group controlId="ContCommentID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                        <div style={{ width: "18%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Comment</Form.Label>
                                                        </div>
                                                        <div style={{ width: "77%" }}>
                                                            <Form.Control type="text" Name="ContCommentID" as="textarea"

                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                        false
                                                                        :
                                                                        true}
                                                                placeholder="Comment" defaultValue={this.state.EditContComment} />
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                        </Accordion>
                                        <Accordion title="Project Deliverables">
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%", paddingBottom: "15px" }}>
                                                    <div style={{ width: "100%", textAlign: "left", backgroundColor: "#D3D3D3" }}>
                                                        <span style={{ fontWeight: "bold" }} >Project Deliverables (select at least one from list)</span>
                                                    </div>
                                                    <div style={{ width: "100%", marginTop: "10px" }}>
                                                        {this.state.ProjectDeliveryListsOne && this.state.ProjectDeliveryListsOne.map((Pro, i) =>
                                                            <Form.Group controlId={"ProOne" + Pro.dmcProjectDeliverableId} style={{ display: "flex" }}>
                                                                <div style={{ width: "25%" }}>
                                                                    <Form.Label style={{ display: "table-cell", textAlign: "right" }}>{Pro.projectDeliverableName}:</Form.Label>
                                                                </div>
                                                                <div style={{ width: "2%" }}>
                                                                </div>
                                                                <div style={{ width: "73%" }}>
                                                                    <Form.Check
                                                                        value={Pro.dmcProjectDeliverableId}
                                                                        type="checkbox"
                                                                        Name={"ProOne" + Pro.dmcProjectDeliverableId}
                                                                        label=""
                                                                        disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                            true
                                                                            :
                                                                            this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                false
                                                                                :
                                                                                true}
                                                                        defaultChecked={Pro.response == "Yes" ? true : false}
                                                                    />
                                                                </div>

                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%" }}>
                                                    <div style={{ width: "100%", textAlign: "left", backgroundColor: "#D3D3D3" }}>
                                                        <span style={{ fontWeight: "bold" }} >Email Notification Groups</span>
                                                    </div>
                                                    <div style={{ width: "100%", marginTop: "10px" }}>
                                                        {this.state.ProjectDeliveryListsTwo && this.state.ProjectDeliveryListsTwo.map((Pro, i) =>
                                                            <Form.Group controlId={"ProTwo" + Pro.dmcNotificationRoleId} style={{ display: "flex" }}>
                                                                <div style={{ width: "2%" }}>
                                                                </div>
                                                                <div style={{ width: "92%", textAlign: "left" }}>
                                                                    <Form.Check
                                                                        value={Pro.dmcNotificationRoleId}
                                                                        type="checkbox"
                                                                        Name={"ProTwo" + Pro.dmcNotificationRoleId}
                                                                        label={Pro.dmcNotificationName}

                                                                        disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                            true
                                                                            :
                                                                            this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                                false
                                                                                :
                                                                                true}
                                                                        defaultChecked={Pro.response == "Yes" ? true : false}
                                                                    />
                                                                </div>

                                                            </Form.Group>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                        </Accordion>
                                        <Accordion title="Review/Approval Section">
                                            <div style={{ width: "80%" }} >
                                                <div style={{ fontWeight: "bold", textAlign: "left", backgroundColor: "#D3D3D3" }}>
                                                    <span >Production Design</span>
                                                </div>
                                                <div style={{ width: "100%", display: "flex", paddingBottom: "20px", marginTop: "10px" }}>
                                                    <Form.Group controlId="RevisionDate" style={{ width: "30%", display: "flex" }}>
                                                        <div style={{ width: "50%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Revision Date</Form.Label>
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            <DatePicker onFocus={focusout}
                                                                selected={this.state.RevisionDate}
                                                                onChange={this.revisionHandleChange}
                                                                name="startDate"

                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                        false
                                                                        :
                                                                        true}
                                                                dateFormat="MM/dd/yyyy" className="form-control" name="RevisionDate" placeholder="Revision Date"

                                                            />
                                                        </div>
                                                    </Form.Group>
                                                    <div style={{ width: "5%" }}>
                                                    </div>
                                                    <Form.Group controlId="RevisedByID" style={{ width: "40%", display: "flex" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Revised By</Form.Label>
                                                        </div>
                                                        <div style={{ width: "60%", position: "relative" }}>
                                                            <Form.Control type="text" name="RevisedByID"

                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                        false
                                                                        :
                                                                        true}
                                                                placeholder="RevisedBy" onChange={e => onChangeHandlerRevisedBy(e.target.value)} value={this.state.RevisedBy} />
                                                            <div className="UserAutoCompletediv2" >
                                                                {this.state.filteredSuggestionsRevisedBy && this.state.filteredSuggestionsRevisedBy.map((Suggestions, i) =>
                                                                    <div key={i} className="UserAutoComplete" onClick={() => onSuggestionHandlerRevisedBy(Suggestions.dmcUserName, Suggestions.dmcUserId)}> {Suggestions.dmcUserName} </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                    <div style={{ width: "5%" }}>
                                                    </div>
                                                    <Form.Group controlId="RevNoID" style={{ width: "20%", display: "flex" }}>
                                                        <div style={{ width: "50%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Rev No</Form.Label>
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            <Form.Control type="text" Name="RevNoID" onFocus={focusout}

                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                        false
                                                                        :
                                                                        true}
                                                                placeholder="Rev No" defaultValue={this.state.EditRevNumber} />
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                            <div style={{ display: "flex", width: "80%" }} >
                                                <div style={{ width: "100%" }}>

                                                    <Form.Group controlId="RevisionCommentID" style={{ display: "flex", paddingBottom: "15px" }}>
                                                        <div style={{ width: "15%" }}>
                                                            <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Comment</Form.Label>
                                                        </div>
                                                        <div style={{ width: "85%" }}>
                                                            <Form.Control type="text" Name="RevisionCommentID" as="textarea" onFocus={focusout}

                                                                disabled={this.state.IsApprovedByAdminAndAtex == false ?
                                                                    true
                                                                    :
                                                                    this.state.LoginRoleName.toLowerCase().indexOf("revision") > -1 || this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ?
                                                                        false
                                                                        :
                                                                        true}
                                                                placeholder="Comment" defaultValue={this.state.EditRevComments} />
                                                        </div>
                                                    </Form.Group>
                                                </div>

                                            </div>
                                            <div style={{ width: "80%" }} >
                                                <div style={{ fontWeight: "bold", textAlign: "left", backgroundColor: "#D3D3D3" }}><span>ATEX APPROVAL (edit only by Atex Admin)</span></div>
                                                <div>
                                                    {this.state.FinalApprlByArexAdmin && this.state.FinalApprlByArexAdmin.map((str, i) =>
                                                        <Form.Group controlId={str.id} style={{ display: "flex", paddingBottom: "10px", paddingTop: "10px" }} >
                                                            <div style={{ width: "15%" }}>
                                                                <Form.Label style={{ display: "table-cell", textAlign: "left" }}>Approval Status</Form.Label>
                                                            </div>
                                                            <div style={{ width: "80%", display: "flex", textAlign: "left" }}>
                                                                <div style={{ width: "15%" }}>
                                                                    <Form.Check
                                                                        value="Approved"
                                                                        type="radio"
                                                                        name={str.id}
                                                                        label="Approved" disabled={this.state.IsApprovedByAdminAndAtex === true && this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1 && this.state.RequestedDate != null ?
                                                                            false
                                                                            :
                                                                            true}
                                                                        defaultChecked={str.val == "Approved" ? true : false}
                                                                    />
                                                                </div>
                                                                <div style={{ width: "15%" }}>
                                                                    <Form.Check
                                                                        value="Rejected"
                                                                        type="radio"
                                                                        name={str.id}

                                                                        label="Rejected" disabled={this.state.IsApprovedByAdminAndAtex === true && this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1 && this.state.RequestedDate != null ?
                                                                            false
                                                                            :
                                                                            true}
                                                                        defaultChecked={str.val == "Rejected" ? true : false}
                                                                    />
                                                                </div>
                                                                <div style={{ width: "80%" }}>
                                                                    <Form.Check
                                                                        value="None"
                                                                        type="radio"
                                                                        name={str.id}
                                                                        label="None" disabled={this.state.IsApprovedByAdminAndAtex === true && this.state.LoginRoleName.toLowerCase().indexOf("atex") > -1 && this.state.RequestedDate != null ?
                                                                            false
                                                                            :
                                                                            true}
                                                                        defaultChecked={str.val == null ? true : false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Form.Group>
                                                    )}
                                                </div>

                                            </div>

                                            <div style={{ width: "80%" }} >

                                                <div style={{ fontWeight: "bold", textAlign: "left", backgroundColor: "#D3D3D3" }}>  <span>Review/Approval Section</span></div>
                                                <div style={{ width: "100%", display: "flex", marginTop: "10px" }}>
                                                    <div style={{ width: "17%" }}>
                                                        <span style={{ display: "table-cell", textAlign: "left" }}>Complete</span>
                                                    </div>
                                                    <div style={{ width: "30%" }}>
                                                        <span style={{ float: "left" }}>
                                                            {this.state.EditStatusID && this.state.Stchgs.map(AffDept =>
                                                                <Toggle
                                                                    defaultChecked={this.state.EditStatusID == "4" ? true : false}
                                                                    onChange={e => StatusDRRComplete(e.target.checked)}

                                                                    disabled={
                                                                        (this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1) && this.state.FinalApprlByArexAdminVal === "Approved" ?
                                                                            false
                                                                            :
                                                                            true}
                                                                    id='cheese-status'
                                                                    icons={{
                                                                        checked: "YES",
                                                                        unchecked: "NO",
                                                                    }}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>

                                                    <div style={{ width: "65%" }}>

                                                    </div>

                                                </div>

                                            </div>
                                        </Accordion>

                                    </div>
                                </Tab>

                            </Tabs>

                        </div>
                    </Form>
                </div>

            </>
        )
    }
    async GetAllDetails() {
        if (this.state.RevID > 0) {
            await fetch('DashBoard/GetRequestDetailsByID/' + this.state.RevID)
                .then(response => response.json())
                .then(data => {
                    for (const str of data) {
                        this.setState({
                            TabTitle: "Update Request",
                            btnsubmit: "Update",
                            EditDeptID: str.dmcDepartmentId,
                            UserName: str.dmcUserName,
                            UserID: str.dmcUserId,
                            startDate: new Date(str.proposedByDate),
                            EditReason: str.reason,
                            EditGA: str.garef,
                            EditStchg: str.structuralChange == '' ? "NoSelect" : str.structuralChange,
                            EditUrgent: str.urgency,
                            ApprlByArexAdminVal: str.atexAppproval,
                            ApprlByAdminVal: str.adminApproval,
                            ApprlByArexAdmin: [{ id: "ApprlByArexAdmin", val: str.atexAppproval }],
                            ApprlByAdmin: [{ id: "ApprlByAdmin", val: str.adminApproval }],
                            IsApprovedByAdmin: str.adminApproval == "Approved" ? true : false,
                            IsApprovedByAtex: str.isAtexRequired == 'Yes' ? str.atexAppproval == "Approved" ? true : false : true,
                            IsApprovedByAdminAndAtex: str.adminApproval == "Approved" ? str.isAtexRequired == 'Yes' ? str.atexAppproval == "Approved" ? true : false : true : false,
                            EditBusinessSegment: str.businessSegment,
                            EditProductFamily: str.productFamily,
                            EditProductModel: str.productModel,
                            EditInternalContainmentPlan: str.internalContainmentPlan,
                            EditFieldContainmentPlan: str.fieldContainmentPlan,
                            EditContComment: str.contComment,
                            RevisedBy: str.revisedBy,
                            RevisedByID: str.revisedByID,
                            RevisionDate: new Date(str.strRevisionDate),
                            EditRevNumber: str.revNumber,
                            EditRevComments: str.revComments,
                            EditStatusID: str.dmcDrrstatusId,
                            IsAtexRequired: str.isAtexRequired == '' ? "NoSelect" : str.isAtexRequired,
                            FileCopy: str.fileCopy == '' ? "NoSelect" : str.fileCopy,
                            GlobalChange: str.globalChange == '' ? "NoSelect" : str.globalChange,
                            RequestedDate: str.requestedDate,
                            StatusDRRComplete: str.dmcDrrstatusId === 4 ? true : false,
                            DisableVal: str.requestedDate != null ? this.state.LoginRoleName.toLowerCase().indexOf("reviewer") > -1 ? false : true : this.state.ExistsLoginUserMailId.toLowerCase() != this.state.LoginUserMailID.toLowerCase() && this.state.LoginRoleName.toLowerCase() == "users" && this.state.ExistsLoginUserMailId != "" ? true : false,
                            GRevisionDate: str.strGRevisionDate != "" ? new Date(str.strGRevisionDate) : this.state.GRevisionDate ,
                            GRevisionNotes: str.gRevisionNotes,
                            GRevisionBy: str.gRevisionBy,
                            GRevisionByID: str.gRevisionByID,
                            FinalApprlByArexAdminVal: str.finalAtexApproval,
                            FinalApprlByArexAdmin: [{ id: "FinalAtexApproval", val: str.finalAtexApproval }]
                           


                        });
                    }
                });

        }
        else {

            await fetch('DashBoard/GetAutoGenID', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }

            })
                .then(res => res.json())
                .then((result) => {
                    if (result.msg == "") {
                        this.setState({
                            AutoGenID: result.autoGenID
                        });
                    }
                    else {
                        alert(result.msg);
                    }

                },
                    (error) => {
                        alert('Failed');

                    })
            this.setState({
                ApprlByArexAdmin: [{ id: "ApprlByArexAdmin", val: null }],
                FinalApprlByArexAdmin: [{ id: "FinalAtexApproval", val: null }],
                ApprlByAdmin: [{ id: "ApprlByAdmin", val: null }],
                EditStchg: "NoSelect",
                IsAtexRequired: "NoSelect",
                FileCopy: "NoSelect",
                GlobalChange: "NoSelect",
                EditStatusID: "1"

            });
            localStorage.setItem('CheckDrawing', null);
        }


        await fetch('CommonDropDownLists/GetAllDeptList')
            .then(response => response.json())
            .then(data => {
                this.setState({ DeptLists: data });
            });

        await fetch('CommonDropDownLists/GetAllUsersName')
            .then(response => response.json())
            .then(data => {
                this.setState({ AllUsers: data });
            });

        await fetch('DashBoard/GetAffDeptList/' + this.state.RevID)
            .then(response => response.json())
            .then(data => {
                this.setState({ AffDepts: data });
            });
        await fetch('CommonDropDownLists/GetRequirementList/' + this.state.RevID)
            .then(response => response.json())
            .then(data => {
                var val = "";
                for (const str of data) {
                    if (str.responce == "Yes")
                        val = val + "DisableReq" + str.dmcRequirementId + "^"
                }
                this.setState({ RequirementList: data, ReqInput: val });
            });
        await fetch('CommonDropDownLists/GetBusinessSegmentList')
            .then(response => response.json())
            .then(data => {
                this.setState({ BusinessSegmentList: data });
            });
        await fetch('CommonDropDownLists/GetProductFamilyList')
            .then(response => response.json())
            .then(data => {
                this.setState({ ProductFamilyList: data });
            });
        await fetch('CommonDropDownLists/GetInternalContainmentPlanModelList')
            .then(response => response.json())
            .then(data => {
                this.setState({ InternalPlanList: data });
            });
        await fetch('CommonDropDownLists/GetFieldContainmentPlanList')
            .then(response => response.json())
            .then(data => {
                this.setState({ FieldPlanList: data });
            });
        await fetch('CommonDropDownLists/GetOutputEvidenceList')
            .then(response => response.json())
            .then(data => {
                this.setState({ OutEvidentList: data });
            });
        await fetch('CommonDropDownLists/GetValidationOutputEvidenceList')
            .then(response => response.json())
            .then(data => {
                this.setState({ OutValidEvidentList: data });
            });
        await fetch('CommonDropDownLists/GetProjectDeliveryListsOne/' + this.state.RevID)
            .then(response => response.json())
            .then(data => {
                this.setState({ ProjectDeliveryListsOne: data });
            });
        await fetch('CommonDropDownLists/GetFinalNotificationList/' + this.state.RevID)
            .then(response => response.json())
            .then(data => {
                this.setState({ ProjectDeliveryListsTwo: data });
            });

        this.setState({ isActive: false });
    }
    async GetAllDrawings(revID) {
        let DrawingData = [];
        let DrawingPartData = [];
        let DrawingDataList = [];
        await fetch('DashBoard/GetAllDrawings/' + revID)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    const SubDrawingData = data;
                    DrawingData = SubDrawingData;
                }

            });
        if (DrawingData.length > 0) {
            await fetch('DashBoard/GetAllDrawingsParts/' + revID)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        const SubDrawingPartData = data;
                        DrawingPartData = SubDrawingPartData;
                    }

                });

            for (const str of DrawingData) {
                let filteredPartData = []
                filteredPartData = DrawingPartData.filter(part => {
                    return part.dmcDrawingRevDrawingId == str.dmcDrawingRevDrawingId
                });
                let DrawingPartDataList = [];
                for (const pro of filteredPartData) {
                    DrawingPartDataList.push({
                        "Part": pro.part,
                        "Description": pro.partDescription

                    });
                }

                DrawingDataList.push({
                    "Drawing": str.dwgNumber,
                    "Parts": DrawingPartDataList

                });

            }
        }
        return DrawingDataList;

    }
    async CheckIsWorkFlowMailSent(revID, SendEmailWorkFlowType) {
        var IsWorkFlowEmailSent = "";
        await fetch('DashBoard/CheckIsWorkFlowMailSent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                DmcDrawingRevId: revID,
                SendEmailWorkFlowType: "" + SendEmailWorkFlowType + "",
            })
        })
            .then(res => res.json())
            .then(data => {
                IsWorkFlowEmailSent = data.isWorkFlowEmailSent;
            });

        return IsWorkFlowEmailSent
    }
    async GetUserEmailByUserID(UserID) {
        var UserEmailID = "";
        await fetch('CommonDropDownLists/GetEmailByUserID/' + UserID)
            .then(response => response.json())
            .then(data => {
                UserEmailID = data.userEmailID;

            });


        return UserEmailID
    }

    async SendSubmottedWorkFlowEmail(event, revID, AffDepts, EmailNotifGroups, StatusDRRComplete, RequestedDate) {
        var SendEmailWorkFlowType = "";
        var IsWorkFlowEmailSent = true;
        if (this.state.BtnName === "submit" && RequestedDate === null && event.target.GRevisionReqReset.checked === false) {
            SendEmailWorkFlowType = "RequestSubmitted";
            IsWorkFlowEmailSent = await this.CheckIsWorkFlowMailSent(revID, SendEmailWorkFlowType);
            /*this.SendSubmottedWorkFlowEmail(event, this.state.RevID, this.state.AffDepts, this.state.ProjectDeliveryListsTwo, "RequestSubmitted", StatusDRRComplete);*/
        }
        else if (RequestedDate !== null && event.target.ApprlByAdmin.value === "Approved" && event.target.GRevisionReqReset.checked === false && event.target.FinalAtexApproval.value !== "Approved" && ((event.target.IsAtexRequired.value === "Yes" && event.target.ApprlByArexAdmin.value === "Approved") || event.target.IsAtexRequired.value === "No")) {
            SendEmailWorkFlowType = "RequestApproved";
            IsWorkFlowEmailSent = await this.CheckIsWorkFlowMailSent(revID, SendEmailWorkFlowType);
            if (IsWorkFlowEmailSent === "true") {
                if (RequestedDate != null && StatusDRRComplete === 4) {
                    SendEmailWorkFlowType = "RequestCompleted";
                    IsWorkFlowEmailSent = await this.CheckIsWorkFlowMailSent(revID, SendEmailWorkFlowType);
                }
            }
        }
        else if (RequestedDate !== null && event.target.FinalAtexApproval.value === "Approved" && event.target.GRevisionReqReset.checked === false) {
            SendEmailWorkFlowType = "AtexRequestApproved";
            IsWorkFlowEmailSent = await this.CheckIsWorkFlowMailSent(revID, SendEmailWorkFlowType);
            if (IsWorkFlowEmailSent === "true") {
                if (RequestedDate != null && StatusDRRComplete == 4) {
                    SendEmailWorkFlowType = "RequestCompleted";
                    IsWorkFlowEmailSent = await this.CheckIsWorkFlowMailSent(revID, SendEmailWorkFlowType);
                }
            }
        }
        var UserEmail = "";


        if (IsWorkFlowEmailSent == "false") {

            if (this.state.UserID > 0 && (this.state.ProPosedByEmailID == null || this.state.ProPosedByEmailID == "")) {
                UserEmail = await this.GetUserEmailByUserID(this.state.UserID);

            }
            else {

                UserEmail = this.state.ProPosedByEmailID;
            }
            if (UserEmail != "") {
                const Drawings = await this.GetAllDrawings(revID);
                let AffDeptsList = [];
                for (const AffDept of AffDepts) {
                    const radioButtons = document.querySelectorAll('input[name="' + AffDept.dmcNotificationRoleId + '"]');
                    for (const radioButton of radioButtons) {
                        var IsChkradioButton = false;
                        if (radioButton.checked && radioButton.defaultValue == "Yes") {

                            IsChkradioButton = true;
                            break;
                        }
                    }
                    AffDeptsList.push({
                        "DeptName": AffDept.dmcNotificationName,
                        "DeptVal": IsChkradioButton

                    });
                }
                let EmailGroupsList = [];
                for (const EmailGroup of EmailNotifGroups) {
                    const checkBox = document.querySelector('input[name=ProTwo' + EmailGroup.dmcNotificationRoleId + ']');
                    var IsChkradioButton = false;
                    if (checkBox.checked == true) {
                        IsChkradioButton = true;
                    }
                    EmailGroupsList.push({
                        "GroupName": EmailGroup.dmcNotificationName,
                        "GroupVal": IsChkradioButton
                    });

                }
                var RequestStatus = "";
                if (StatusDRRComplete == 1) {
                    RequestStatus = "Requested";
                }
                else if (StatusDRRComplete == 2) {
                    RequestStatus = "Approved";
                }
                else if (StatusDRRComplete == 3) {
                    RequestStatus = "Revision Complete";
                }
                else if (StatusDRRComplete == 4) {
                    RequestStatus = "DRR Complete";
                }
                else if (StatusDRRComplete == 5) {
                    RequestStatus = "Rejected";
                }
                else if (StatusDRRComplete == 6) {
                    RequestStatus = "Atex Approved";
                }
                else if (StatusDRRComplete ==7) {
                    RequestStatus = "Atex Rejected";
                }

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Accept", "application/json");
                var raw = JSON.stringify({
                    RecordNumber: parseInt(this.state.RevID),
                    "ActiveUserName": "" + this.state.UserName + "",
                    "File Copy": "" + event.target.FileCopy.value + "",
                    "Global Change": "" + event.target.GlobalChange.value + "",
                    "ATEX": "" + event.target.IsAtexRequired.value + "",
                    "G/A Ref": "" + event.target.GARef.value + "",
                    "Drawings": Drawings,
                    "Proposed By": "" + this.state.UserName + "",
                    "ProposedByEmailID": "" + UserEmail + "",
                    Date: "" + event.target.ProposedByDate.value + "",
                    "Dept Proposing Change": "" + event.target.DeptProposingid.selectedOptions[0].innerText + "",
                    "Reason": "" + event.target.Reason.value + "",
                    "Urgency": "" + event.target.Urgency.value + "",
                    "AffDepts": AffDeptsList,
                    "AdminApproval": "" + event.target.ApprlByAdmin.value + "",
                    "AtexAppproval": "" + event.target.ApprlByArexAdmin.value + "",
                    "InternalContainmentPlan": "" + event.target.InterContPlanID.value + "",
                    "FieldContainmentPlan": "" + event.target.FieldContPlanID.value + "",
                    "EmailNotificationGroups": EmailGroupsList,
                    "DRRRequestStatus": "" + RequestStatus + "",
                    "EmailLinkUrl": "https://dmcengineering.azurewebsites.net?ReqID=" + this.state.RevID


                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var APIUrl = "";
                if (SendEmailWorkFlowType == "RequestSubmitted") {
                    APIUrl = "https://prod-116.westus.logic.azure.com:443/workflows/e2a1f0ed3d1847f49fbbb12b8d806497/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=4orPlZZHs2Ph2vF_cFw50eVCsLKLeKom9fIl6pt55XI";
                }
                else if (SendEmailWorkFlowType == "RequestApproved") {
                    APIUrl = "https://prod-85.westus.logic.azure.com:443/workflows/73f812363190471f9163e0297a4c4d6b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=71X589eNNDO_cNY1tPqnjgm031qcoRFoAnYixiukJRI";
                }
                else if (SendEmailWorkFlowType == "AtexRequestApproved") {
                    APIUrl = "https://prod-22.westus.logic.azure.com:443/workflows/d6a90de1c5864502bc8da577871ba883/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uahl1p2O_GxsyaOhVqQG2qbXTMK5DcHLdOO5foHZlKg";
                }
                else if (SendEmailWorkFlowType == "RequestCompleted") {
                    APIUrl = "https://prod-37.westus.logic.azure.com:443/workflows/a3f91847b59846f18d1c38d81b9f34af/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1MLh951bcketDTMDHdt0DM_b5tuayOTzXCZbfxnSgE8";
                }
              
                var responsestatus = "";
                var responsetext = "";
                await fetch(APIUrl, requestOptions)
                    .then((response) => {
                        // Check the status code
                        if (!response.ok) {
                            // If the status code is not in the 200 range (e.g., 404, 500),
                            responsetext = "";
                            responsestatus = "Request failed with status: " + response.status;
                            /*return "Request failed with status: " + response.status*/
                        }
                        if (response.ok) {
                            responsetext = "";
                            responsestatus = "Request Success with status: " + response.status;
                            /*return "Request Success with status: " + response.status;*/
                        }
                    })
                    .catch((error) => {
                        responsestatus = "Request error: " + error;
                        /*return "Request error: " + error;*/
                    })

                await fetch('DashBoard/AddSendEmailWorkFlow', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DmcDrawingRevId: parseInt(this.state.RevID),
                        SendEmailWorkFlowType: "" + SendEmailWorkFlowType + "",
                        SendEmailWorkFlowStatus: "" + responsestatus + "",
                        SendEmailWorkFlowJson: "" + raw + "",
                        ReceivedResponce: "" + responsetext + ""


                    })
                })
                    .then(res => res.json())
                    .catch((error) => {
                        return alert("Save SendEmailWorkFlow error: " + error);
                    })
            }
            else {
                this.setState({
                    Msg: "Your email-id is not available",
                });
            }
            if (event.target.IsAtexRequired.value == "Yes" && event.target.ApprlByArexAdmin.value == "Approved") {

            }

        }
    }



}
export default NewRequest;