import { NavLink, Link } from "react-router-dom";
import { FaBars, FaHome, FaUser } from "react-icons/fa";
import {AiTwotoneFileExclamation } from "react-icons/ai";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { UserLogin } from '../UserLogin';
import logo from '../../Images/derrick_full_logo.png';

function SideBar(props) {

    const routes = [
        {
            path: "/",
            name: "Dashboard",
            icon: <FaHome />,
        },
        //{
        //    path: "/Department",
        //    name: "Department",
        //    icon: <FaUser />,
        //},
        // {
        //   path: "/messages",
        //   name: "Messages",
        //   icon: <MdMessage />,
        // },
        // {
        //   path: "/analytics",
        //   name: "Analytics",
        //   icon: <BiAnalyse />,
        // },
        {
            path: "/Admin",
            name: "Admin",
            icon: <AiTwotoneFileExclamation />,
            subRoutes: [
                {

                    path: "/DmcUsers",
                    name: "DmcUsers",
                    icon: <FaUser />,
                }
                 
                //{
                //    path: "/FileList",
                //    name: "FileList",
                //    icon: <FaUser />,
                //},
            ],
          
        },
        // {
        //   path: "/order",
        //   name: "Order",
        //   icon: <BsCartCheck />,
        // },
        // {
        //   path: "/settings",
        //   name: "Settings",
        //   icon: <BiCog />,
        //   exact: true,
        //   subRoutes: [
        //     {
        //       path: "/settings/profile",
        //       name: "Profile ",
        //       icon: <FaUser />,
        //     },
        //     {
        //       path: "/settings/2fa",
        //       name: "2FA",
        //       icon: <FaLock />,
        //     },
        //     {
        //       path: "/settings/billing",
        //       name: "Billing",
        //       icon: <FaMoneyBill />,
        //     },
        //   ],
        // },
        // {
        //   path: "/saved",
        //   name: "Saved",
        //   icon: <AiFillHeart />,
        // },
    ];

    //const SideBar = ({ children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const inputAnimation = {
        hidden: {
            width: 0,
            padding: 0,
            transition: {
                duration: 0.2,
            },
        },
        show: {
            width: "140px",
            padding: "5px 15px",
            transition: {
                duration: 0.2,
            },
        },
    };

    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        show: {
            opacity: 1,
            width: "auto",
            transition: {
                duration: 0.5,
            },
        },
    };
    return (
        <>

            <div className="main-container">
                {/*<div style={{ width: "100%", backgroundColor: "black", height: "50px", position: "absolute", display: "flex", padding: "5px 5px 5px 5px" }}>*/}
                {/*    <div style={{ width: "16%" }}></div>*/}
                {/*    <div style={{ width: "64%", color: "white" }}><img src={logo} style={{height:"5vh" }} /></div>*/}
                {/*    <div style={{ width: "20%", color: "white", textAlign: "right", paddingRight: "20px" }}> <UserLogin {...props} /></div>*/}
                {/*</div>*/}
                <div style={{ zIndex: "999" }}>
                    <motion.div
                        animate={{
                            width: isOpen ? "200px" : "45px",

                            transition: {
                                duration: 0.5,
                                type: "spring",
                                damping: 10,
                            },
                        }}
                        className={`sidebar `}
                    >
                        <div className="top_section">
                            {/* <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  DoSomeCoding
                </motion.h1>
              )}
            </AnimatePresence> */}

                            <div className="bars">
                                <FaBars onClick={toggle} />
                            </div>
                        </div>
                        {/* <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}
                        <section className="routes">
                            {routes.map((route, index) => {
                                if (route.name == "Admin") {
                                    if (localStorage.getItem("UserIsAdmin") == "true") {
                                      
                                        if (route.subRoutes) {
                                            return (
                                                <SidebarMenu
                                                    setIsOpen={setIsOpen}
                                                    route={route}
                                                    showAnimation={showAnimation}
                                                    isOpen={isOpen}

                                                />
                                            );
                                        }


                                        return (
                                            <NavLink
                                                to={route.path}
                                                key={index}
                                                className="link"
                                                activeClassName="active"
                                            >
                                                <div className="icon">{route.icon}</div>
                                                <AnimatePresence>
                                                    {isOpen && (
                                                        <motion.div
                                                            variants={showAnimation}
                                                            initial="hidden"
                                                            animate="show"
                                                            exit="hidden"
                                                            className="link_text"
                                                        >
                                                            {route.name}
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </NavLink>
                                        );
                                    }
                                }
                                else {
                                    if (route.subRoutes) {
                                        return (
                                            <SidebarMenu
                                                setIsOpen={setIsOpen}
                                                route={route}
                                                showAnimation={showAnimation}
                                                isOpen={isOpen}

                                            />
                                        );
                                    }


                                    return (
                                        <NavLink
                                            to={route.path}
                                            key={index}
                                            className="link"
                                            activeClassName="active"
                                        >
                                            <div className="icon">{route.icon}</div>
                                            <AnimatePresence>
                                                {isOpen && (
                                                    <motion.div
                                                        variants={showAnimation}
                                                        initial="hidden"
                                                        animate="show"
                                                        exit="hidden"
                                                        className="link_text"
                                                    >
                                                        {route.name}
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </NavLink>
                                    );
                                }
                            })}
                        </section>
                    </motion.div>
                </div>

                <main>
                    <div>
                        <div style={{ width: "100%", backgroundColor: "black", height: "50px", display: "flex" }}>
                            <div style={{ width: "80%", color: "white" }}> <Link to={{
                                pathname: '/',

                            }}><img src={logo} style={{ height: "3.8vh" }} /> </Link></div>
                         <div style={{ width: "20%", color: "white", textAlign: "right", paddingRight: "20px" }}> <UserLogin {...props} /></div>
                        </div>
                        <div style={{ marginTop:"5px",padding:"10px" }}>
                      {props.children}
                        </div>
                    </div>
                </main>
            </div>

        </>
    );
    //}
}

export default SideBar;
