import React, { Component } from 'react';
import './components/Sidebar/SideMenu.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
/*import { GraphData } from './components/GraphData';*/
import { withAuth } from './msal/MsalAuthProvider';
import SideBar from './components/Sidebar/SideBar';
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { NewRequest } from './components/pages/Dashboard/NewRequest';
import DmcUsers from "./components/pages/Admin/DmcUsers";
/*import FileList from "./components/pages/File/FileList";*/
import UserDetails from "./components/pages/Dashboard/UserDetails";


class RootApp extends Component {

    render() {
        if (this.props.auth.requestID > 0) {
            return (
                <>
                    <div>
                        <UserDetails ReqID={this.props.auth.requestID} {...this.props} />
                    </div>
                    <div className="footer">� Derrick Corporation</div>
                </>
            );
        }
        return (
            <>
                <div>
                   
                <Router>
                    <SideBar {...this.props} >
                    <Switch>
                                <Route path='/' exact  component={Dashboard} />
                                <Route path='/DmcUsers' component={DmcUsers} />
                                <Route path='/NewRequest' component={NewRequest} />
                                <Route path='/RequestDetails' component={UserDetails}  />

                    </Switch>
                    </SideBar>
                    </Router>
                </div>
                <div className="footer">� Derrick Corporation</div>
            </>
        );
    }
}

export const App = withAuth(RootApp);
/*export const App = RootApp;*/
