import React, { Component } from 'react';
import '../Dashboard/Dashboard.css';
import { Table } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FaEdit, FaSort, FaSortDown, FaSortUp, FaSearch, FaTrash } from "react-icons/fa";
import { Link } from 'react-router-dom';
import TableScrollbar from 'react-table-scrollbar';

export class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = { AllRequests: [], DubAllRequests: [], LoginUserMailID: localStorage.getItem("LoginUserID"), RoleName: "", LoginUserName: "", LoginUserID: 0, DisableVal: false, isActive: true, SortByOrder: "",IsAdmin:false }

    }


    GetLoginRoleName() {
        try {

            fetch('DashBoard/GetLoginRoleName/' + this.state.LoginUserMailID)
                .then(response => response.json())
                .then(data => {
                    if (data.roleName == "") {
                        this.setState({ DisableVal: true });
                    }
                    else {
                        this.setState({ RoleName: data.roleName, LoginUserName: data.loginUserName, LoginUserID: data.loginUserID, IsAdmin: data.isAdmin });
                    }

                });
        } catch (error) {
            this.setState({ DisableVal: true, isActive: false });
            alert(error);
        }
    }

    refreshList() {
        try {
            fetch('DashBoard/GetAllNewRequest/' + this.state.LoginUserMailID)
                .then(response => response.json())
                .then(data => {
                    this.setState({ AllRequests: data });
                    this.setState({ isActive: false });
                });
        } catch (error) {
            this.setState({ DisableVal: true, isActive: false });
            alert(error);
        }
    }

    async componentDidMount() {
        this.GetLoginRoleName();
        this.refreshList();
    }
       formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}

    render() {


        const myComponent = {
            /*  width: '500px',*/
            height: '600px',
            overflowX: 'hidden',
            overflowY: 'scroll',
            top: "100px"
        };
        const Loading = {
            backgroundColor: "black"
        };


        //const { AllRequests } = this.state;
        var SortByorder = "ascending";
        const DataSorting = (SortBy) => {
            if (SortBy == "dmcDrawingRevId") {
                if (this.state.SortByOrder.indexOf("dmcDrawingRevId^ascending") > -1) {
                    this.setState({ SortByOrder: "dmcDrawingRevId^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "dmcDrawingRevId^ascending" });
                }

            }
            else if (SortBy == "userName") {
                if (this.state.SortByOrder.indexOf("userName^ascending") > -1) {
                    this.setState({ SortByOrder: "userName^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "userName^ascending" });
                }

            }
            else if (SortBy == "proposedByDate") {
                if (this.state.SortByOrder.indexOf("proposedByDate^ascending") > -1) {
                    this.setState({ SortByOrder: "proposedByDate^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "proposedByDate^ascending" });
                }

            }
            else if (SortBy == "statusName") {
                if (this.state.SortByOrder.indexOf("statusName^ascending") > -1) {
                    this.setState({ SortByOrder: "statusName^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "statusName^ascending" });
                }

            }
            else if (SortBy == "homeCode2") {
                if (this.state.SortByOrder.indexOf("homeCode2^ascending") > -1) {
                    this.setState({ SortByOrder: "homeCode2^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "homeCode2^ascending" });
                }

            }
            else if (SortBy == "gaRef") {
                if (this.state.SortByOrder.indexOf("gaRef^ascending") > -1) {
                    this.setState({ SortByOrder: "gaRef^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "gaRef^ascending" });
                }

            }
            else if (SortBy == "isatexRequired") {
                if (this.state.SortByOrder.indexOf("isatexRequired^ascending") > -1) {
                    this.setState({ SortByOrder: "isatexRequired^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "isatexRequired^ascending" });
                }

            }
            if (SortBy == "totDrawing") {
                if (this.state.SortByOrder.indexOf("totDrawing^ascending") > -1) {
                    this.setState({ SortByOrder: "totDrawing^descending" });
                    SortByorder = "descending";
                }
                else {
                    this.setState({ SortByOrder: "totDrawing^ascending" });
                }
            }
            if (SortBy == "proposedByDate") {
                const strfilter = this.state.AllRequests.sort((a, b) => {
                    if (new Date(a[SortBy]) > new Date(b[SortBy])) {
                        return SortByorder === 'ascending' ? 1 : -1;

                    }
                    if (new Date(a[SortBy]) < new Date(b[SortBy])) {
                        return SortByorder === 'ascending' ? -1 : 1;
                    }
                    return 0;
                });
                if (this.state.DubAllRequests.length == 0) {
                    this.setState({ DubAllRequests: this.state.AllRequests });
                }
                this.setState({ AllRequests: strfilter });
            }
            else {
                const strfilter = this.state.AllRequests.sort((a, b) => {
                    if (a[SortBy] >  b[SortBy]) {
                        return SortByorder === 'ascending' ? 1 : -1;

                    }
                    if (a[SortBy] < b[SortBy]) {
                        return SortByorder === 'ascending' ? -1 : 1;
                    }
                    return 0;
                });
                if (this.state.DubAllRequests.length == 0) {
                    this.setState({ DubAllRequests: this.state.AllRequests });
                }
                this.setState({ AllRequests: strfilter });
            }
          

        }
        const handleSelectChange = (event) => {
            if (event.target.value != "0") {
                var AllRequests = [];
                if (this.state.DubAllRequests.length == 0) {
                    this.setState({ DubAllRequests: this.state.AllRequests });
                    AllRequests = this.state.AllRequests;
                }
                else {
                    AllRequests = this.state.DubAllRequests;
                }
                var filteredSuggestions = [];
                filteredSuggestions = AllRequests.filter(usr => {
                    return (usr.statusName == event.target.value)
                });
                this.setState({ AllRequests: filteredSuggestions });
            }
            else {
                if (this.state.DubAllRequests.length > 0) {
                    this.setState({ AllRequests: this.state.DubAllRequests });
                    this.setState({ DubAllRequests: [] });
                }
            }

        };

        const TextSearchBoxValBybtn = () => {

            const InputReq = document.querySelector('input[name=SearchByID]');
            if (InputReq.value.trim() != "") {
                var AllRequests = [];
                if (this.state.DubAllRequests.length == 0) {
                    this.setState({ DubAllRequests: this.state.AllRequests });
                    AllRequests = this.state.AllRequests;
                }
                else {
                    AllRequests = this.state.DubAllRequests;
                }
                var filteredSuggestions = [];
                filteredSuggestions = AllRequests.filter(usr => {
                    return (usr.gaRef.indexOf(InputReq.value) > -1 || usr.revDrawings.indexOf(InputReq.value) > -1)
                });
                this.setState({ AllRequests: filteredSuggestions });
            }
            else {
                if (this.state.DubAllRequests.length > 0) {
                    this.setState({ AllRequests: this.state.DubAllRequests });
                    this.setState({ DubAllRequests:[]});
                }
            }
        }
        const TextSearchBoxVal = (val) => {
            if (val.trim() != "") {
                var AllRequests = [];
                if (this.state.DubAllRequests.length == 0) {
                    this.setState({ DubAllRequests: this.state.AllRequests });
                    AllRequests = this.state.AllRequests;
                }
                else {
                    AllRequests = this.state.DubAllRequests;
                }
                var filteredSuggestions = [];
                filteredSuggestions = AllRequests.filter(usr => {
                    return (usr.gaRef.indexOf(val.trim()) > -1 || usr.revDrawings.indexOf(val.trim()) > -1)
                });
                this.setState({ AllRequests: filteredSuggestions });
            }
            else {
                if (this.state.DubAllRequests.length > 0) {
                    this.setState({ AllRequests: this.state.DubAllRequests });
                    this.setState({ DubAllRequests: [] });
                }
            }
        }
        const DeleteRequest = (RequestId) => {
            if (window.confirm('Are you sure you want to delete?')) {
                fetch('DashBoard/DeleteRequest', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DmcDrawingRevId: RequestId,
                        DisableBy: "" + this.state.LoginUserMailID + "",

                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        alert('Request deleted successfully');
                        this.refreshList();
                        
                    },
                (error) => {
                            alert('Failed');

                        });
               
            }
        }



        return (

            <div className={this.state.isActive == true ? 'loading' : ''} >
                <div style={{ width: "100%", display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      
                        <div style={{
                            display: this.state.DisableVal == true ? "none" : this.state.RoleName.toLowerCase().indexOf("users") > -1 ? "block" : "none"
                        }}>
                            <ButtonToolbar>
                                <Link to={{
                                    pathname: '/NewRequest',
                                    state: {
                                        RevID: "0",
                                        ExistsLoginUserMailId: "",
                                        ExistsLoginRoleName: "",
                                        LoginRoleName: this.state.RoleName,
                                        LoginUserName: this.state.LoginUserName,
                                        LoginUserID: this.state.LoginUserID
                                    }
                                }} >
                                    <Button style={{ backgroundColor: "black", color: "white", padding: "0.200rem 0.50rem" }}
                                        disabled={this.state.DisableVal == true ?
                                            true
                                            :
                                            this.state.RoleName.toLowerCase().indexOf("users") > -1 ?
                                                false
                                                :
                                                true
                                        }>
                                        <span>New Request</span>
                                    </Button>
                                </Link>
                            </ButtonToolbar>
                        </div>
                    </div>
                    <div style={{ width: "50%" }}>
                        <div className="search-container">
                            <input id="SearchByID" name="SearchByID" type="text" placeholder="Search By GA Ref/Drw No" onChange={(e) => TextSearchBoxVal(e.target.value)}  />
                            <button type="submit"  onClick={() => TextSearchBoxValBybtn()}><FaSearch /></button>

                        </div>
                    </div>
                </div>

                <div style={{ marginTop: "15px", scrollbarColor: "Darkgray #808080", scrollbarWidth: "thin", display: this.state.DisableVal == true ? "none" : "block" }} >
                    <div className="table-container" >
                    <Table striped bordered hover size="sm" >
                        <thead>
                                <tr>
                                    <th  style={{ width: "4%", display: this.state.IsAdmin == true ? "" : "none" }}>Delete</th>
                                <th style={{ width: "4%" }}>Edit</th>
                                <th style={{ cursor: "pointer", width: "8%" }} onClick={() => DataSorting("dmcDrawingRevId")} >DRR ID{this.state.SortByOrder.indexOf("dmcDrawingRevId^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("dmcDrawingRevId^descending") > -1 ? <FaSortUp /> : <FaSort />}</th>
                                <th style={{ cursor: "pointer", width: "12%" }} onClick={() => DataSorting("userName")} >Proposed By{this.state.SortByOrder.indexOf("userName^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("userName^descending") > -1 ? <FaSortUp /> : <FaSort />}</th>
                                <th style={{ cursor: "pointer", width: "12%" }} onClick={() => DataSorting("proposedByDate")}>Proposed Date{this.state.SortByOrder.indexOf("proposedByDate^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("proposedByDate^descending") > -1 ? <FaSortUp /> : <FaSort />}</th>
                                    <th style={{ cursor: "pointer", width: "15%" }} ><span onClick={() => DataSorting("statusName")}>Status{this.state.SortByOrder.indexOf("statusName^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("statusName^descending") > -1 ? <FaSortUp /> : <FaSort />}</span>
                                        <span><select onChange={handleSelectChange} id="dropdown" style={{ fontSize: "10px", cursor: "pointer", width: "45%" }} >
                                        <option value="0">Filter Status</option>
                                            <option value="DRR Complete">DRR Complete</option>
                                            <option value="Revision Complete">Rev Complete</option>
                                            <option value="Approved">Approved</option>
                                            <option value="Requested">Requested</option>
                                            <option value="Rejected">Rejected</option>
                                    </select></span></th>
                                <th style={{ cursor: "pointer", width: "16%" }} onClick={() => DataSorting("homeCode2")}>Proposed Dept{this.state.SortByOrder.indexOf("homeCode2^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("homeCode2^descending") > -1 ? <FaSortUp /> : <FaSort />}</th>
                                    <th style={{ cursor: "pointer", width: "15%" }} onClick={() => DataSorting("gaRef")}>G/A Ref{this.state.SortByOrder.indexOf("gaRef^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("gaRef^descending") > -1 ? <FaSortUp /> : <FaSort />}</th>
                                    <th style={{ cursor: "pointer", width: "8%" }} onClick={() => DataSorting("isatexRequired")}>Atex Value{this.state.SortByOrder.indexOf("isatexRequired^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("isatexRequired^descending") > -1 ? <FaSortUp /> : <FaSort />}</th>
                                <th style={{ cursor: "pointer", width: "6%"  }} onClick={() => DataSorting("totDrawing")} >DWG#s{this.state.SortByOrder.indexOf("totDrawing^ascending") > -1 ? <FaSortDown /> : this.state.SortByOrder.indexOf("totDrawing^descending") > -1 ? <FaSortUp /> : <FaSort />}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.AllRequests.map((Request, index) =>
                                <tr key={index}>
                                    <td style={{display: this.state.IsAdmin == true ? "" : "none" }}>
                                        <Button  className="mr-1" variant="danger" onClick={() => DeleteRequest(Request.dmcDrawingRevId)}>
                                                <FaTrash />
                                            </Button>
                                    </td>
                                    <td style={{ width: "4%" }}>

                                        <Link to={{
                                            pathname: '/NewRequest',
                                            state: {
                                                RevID: Request.dmcDrawingRevId,
                                                ExistsLoginUserMailId: Request.existsLoginUserMailId,
                                                ExistsLoginRoleName: Request.existsLoginRoleName,
                                                LoginRoleName: Request.loginRoleName,
                                                LoginUserName: this.state.LoginUserName,
                                                LoginUserID: this.state.LoginUserID
                                            }
                                        }}>
                                            <Button className="mr-1" variant="info">
                                                <FaEdit />
                                            </Button>
                                        </Link>
                                    </td>
                                    <td style={{ width: "10%" }}>{Request.dmcDrawingRevId}</td>
                                    <td style={{ width: "23%" }}>{Request.userName}</td>
                                    <td style={{ width: "20%" }}>{Request.proposedByDate}</td>
                                    <td style={{ width: "15%" }}>{Request.statusName}</td>
                                    <td style={{ width: "23%" }}>{Request.homeCode2}</td>
                                    <td style={{ width: "23%" }}>{Request.gaRef}</td>
                                    <td style={{ width: "23%" }}>{Request.isatexRequired}</td>
                                    <td style={{ width: "5%" }}>{Request.totDrawing}</td>


                                </tr>)}
                        </tbody>

                    </Table>
                      </div>
                </div>
                <div style={{ marginTop: "15px", color: "red", fontSize: "20px", fontWeight: "bold", textAlign: "center", display: this.state.DisableVal == true ? "block" : "none" }} >
                    Please Create yourself as a User First
                </div>
            </div>



        )
    }
}
export default Dashboard;