import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form, Image, ButtonToolbar } from 'react-bootstrap';
import { FaRegWindowClose, FaPlus, FaMinus, FaEdit, FaTrash, FaArrowCircleDown, FaExclamationTriangle } from "react-icons/fa";
import '../Dashboard/Dashboard.css';
import { Table } from 'react-bootstrap';
import { EditDrawing } from '../Dashboard/EditDrawing';




export class AddDrawing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Msg: '', PartMsg: '', Drawings: [], DrawingParts: [], ShowPartPopUp: false,
            AddParts: [], Part: '', Desc: '', UpdateId: 0, IsUpdate: false, BtnText: "Add Drawing Part",
            isActive: false, IsViewpart: '', ShowAlert: false, ShowAlertMsg: "No"
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitAddPart = this.handleSubmitAddPart.bind(this);
        this.formRef = React.createRef();

    }
    GetAllDrawings() {
        if (this.props.revID > 0) {
            fetch('DashBoard/GetAllDrawings/' + this.props.revID)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        localStorage.setItem('CheckDrawing', "1");
                        this.setState({ ShowAlertMsg: "Yes" });
                    }
                    else {
                        localStorage.setItem('CheckDrawing', null);
                        this.setState({ ShowAlertMsg: "No", ShowAlert: false });
                    }
                    this.setState({ Drawings: data });
                });
        }
        else if (this.props.autoGenID > 0) {
            fetch('DashBoard/TempGetAllDrawings/' + this.props.autoGenID)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        localStorage.setItem('CheckDrawing', "1");
                        this.setState({ ShowAlertMsg: "Yes" });
                    }
                    else {
                        localStorage.setItem('CheckDrawing', null);
                        this.setState({ ShowAlertMsg: "No", ShowAlert: false });
                    }

                    this.setState({ Drawings: data });
                });
        }
        else {
            localStorage.setItem('CheckDrawing', null);
            this.setState({ ShowAlertMsg: "No", ShowAlert: false });
        }

    }
    GetAllDrawingsParts() {

        if (this.props.revID > 0) {
            fetch('DashBoard/GetAllDrawingsParts/' + this.props.revID)
                .then(response => response.json())
                .then(data => {
                    this.setState({ DrawingParts: data });
                });
        }
        else if (this.props.autoGenID > 0) {
            fetch('DashBoard/TempGetAllDrawingsParts/' + this.props.autoGenID)
                .then(response => response.json())
                .then(data => {
                    this.setState({ DrawingParts: data });
                });
        }
    }
    //UpdateAllDrawingsParts(DrawingID) {

    //    if (this.props.revID > 0) {
    //        fetch('DashBoard/GetAllDrawingsParts/' + this.props.revID)
    //            .then(response => response.json())
    //            .then(data => {
    //                this.setState({ DrawingParts: data });
    //            });
    //    }
    //}
    componentDidMount() {
        if (this.props.revID > 0 || this.props.autoGenID > 0) {
            this.setState({ isActive: true });
            this.GetAllDrawings();
            this.GetAllDrawingsParts();
            this.setState({ isActive: false });
        }

    }

    componentDidUpdate() {

        if (localStorage.getItem("UpdateDrawing") == "yes") {
            if (this.props.revID > 0 || this.props.autoGenID > 0) {
                this.setState({ isActive: true });
                this.GetAllDrawings();
                this.GetAllDrawingsParts();
                this.setState({ isActive: false });
                localStorage.setItem('UpdateDrawing', null)
            }
        }

    }
    handleSubmitAddPart(event) {
        this.setState({ isActive: true });
        event.stopPropagation();
        event.preventDefault();
        let filterArry = [];
        if (this.state.IsUpdate == false) {
            let count = this.state.AddParts.length + 1;
            if (this.state.AddParts.length > 0) {
                filterArry = this.state.AddParts;
            }
            filterArry.push({ id: count, partNumber: event.target.Part.value, description: event.target.Description.value })
        }
        else {
            if (this.state.UpdateId > 0) {
                for (const str of this.state.AddParts) {
                    if (this.state.UpdateId == str.id) {
                        filterArry.push({ id: str.id, partNumber: event.target.Part.value, description: event.target.Description.value })
                    }
                    else {
                        filterArry.push({ id: str.id, partNumber: str.partNumber, description: str.description })
                    }

                }
            }
        }

        this.setState({ isActive: false, AddParts: filterArry, ShowPartPopUp: false, UpateId: 0, Part: '', Desc: '', IsUpdate: false, BtnText: "Add Drawing Part" });
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        if (this.state.ShowAlertMsg == "No") {
            if (this.props.revID > 0 || this.props.autoGenID > 0) {
                this.setState({ isActive: true });

                var selectedPartValues = "";
                if (this.state.AddParts.length > 0) {
                    for (const str of this.state.AddParts) {
                        if (str.partNumber != "") {
                            selectedPartValues = selectedPartValues + "^" + str.partNumber + "~" + str.description;
                        }
                    }

                }

                fetch('DashBoard/AddUpdatedDrawing', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        DmcDrawingRevId: parseInt(this.props.revID),
                        DwgNumber: "" + event.target.Drawing.value + "",
                        PartsDetails: "" + selectedPartValues + "",
                        AutoGenId: parseInt(this.props.autoGenID)

                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        localStorage.setItem('UpdateDrawing', "yes")
                        this.setState({
                            Msg: result, isActive: false, AddParts: [], ShowPartPopUp: false, UpateId: 0, Part: '', Desc: '', IsUpdate: false, BtnText: "Add Drawing Part", ShowAlert: false, ShowAlertMsg: "No"
                        });
                        this.GetAllDrawings();
                        this.GetAllDrawingsParts();
                        setTimeout(() => {
                            this.setState({
                                Msg: ''

                            });
                            document.getElementById("CloseModel").click();
                        }, 2000)
                        // alert(result);
                    },
                        (error) => {
                            alert('Failed');
                            this.setState({
                                Msg: '', isActive: false,
                                AddParts: [], ShowPartPopUp: false, UpateId: 0, Part: '', Desc: '', IsUpdate: false, BtnText: "Add Drawing Part", ShowAlert: false, ShowAlertMsg: "No"

                            });
                        })
            }
            else {
                alert("Something went wrong please close popup and try again")
            }
        }
        else {
            this.setState({ ShowAlert: true, ShowAlertMsg:"No" });
        }
    }
    deleteDrawing(id) {
        if (window.confirm('Are you sure?')) {
            this.setState({ isActive: true });
            if (this.props.revID > 0) {
                fetch('DashBoard/DeleteDrawing/' + id, {
                    method: 'DELETE',
                    header: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                    .then((result) => {
                        this.GetAllDrawings();
                        this.setState({ isActive: false });
                    },
                        (error) => {
                            alert('Failed');
                        })
            }
            else if (this.props.autoGenID > 0) {
                fetch('DashBoard/TempDeleteDrawing/' + id, {
                    method: 'DELETE',
                    header: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                    .then((result) => {
                        this.GetAllDrawings();
                        this.setState({ isActive: false });
                    },
                        (error) => {
                            alert('Failed');
                        })
            }
        }
    }
    deleteDrawingPart(DrawingID, Partid) {
        if (window.confirm('Are you sure?')) {
            this.setState({ isActive: true });
            if (this.props.revID > 0) {
                fetch('DashBoard/DeleteDrawingPart/' + Partid, {
                    method: 'DELETE',
                    header: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                    .then((result) => {
                        // this.UpdateAllDrawingsParts(DrawingID);
                        this.GetAllDrawingsParts();
                        this.setState({
                            PartMsg: result, isActive: false
                        });

                        setTimeout(() => {
                            this.setState({
                                PartMsg: ''
                            });
                        }, 1000)

                    },
                        (error) => {
                            alert('Failed');
                        })
            }
            else if (this.props.autoGenID > 0) {
                fetch('DashBoard/TempDeleteDrawingPart/' + Partid, {
                    method: 'DELETE',
                    header: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                    .then((result) => {
                        // this.UpdateAllDrawingsParts(DrawingID);
                        this.GetAllDrawingsParts();
                        this.setState({
                            PartMsg: result, isActive: false
                        });

                        setTimeout(() => {
                            this.setState({
                                PartMsg: ''
                            });
                        }, 1000)

                    },
                        (error) => {
                            alert('Failed');
                        })
            }
        }
    }
    deletePart(id) {
        if (window.confirm('Are you sure?')) {
            let filteredSuggestions = []
            filteredSuggestions = this.state.AddParts.filter(part => {
                return part.id != id
            });
            this.setState({ AddParts: filteredSuggestions });
        }
    }
    UpatePart(Id, Part, Desc) {
        this.setState({ Part: Part, UpdateId: Id, Desc: Desc, ShowPartPopUp: true, IsUpdate: true, BtnText: "Update Drawing Part" });
    }
    handleEdit(editModalShow, dmcDrawingRevDrawingId, dwgNumber) {
        this.setState({
            editModalShow: editModalShow,
            drawingID: dmcDrawingRevDrawingId,
            dwgNumber: dwgNumber

        });
    }
    showParts(DrawingID) {
        //let filteredArry = []
        //filteredArry = this.state.DrawingParts.filter(part => {
        //    return part.dmcDrawingRevDrawingId == DrawingID
        //});
        if (this.state.IsViewpart.indexOf(',' + DrawingID) > -1) {
            this.setState({ IsViewpart: this.state.IsViewpart.replace(',' + DrawingID, '') });
        }
        else {
            this.setState({ IsViewpart: this.state.IsViewpart + ',' + DrawingID });
        }


    }
  
    render() {
        const { drawingID, dwgNumber } = this.state;
        let editModalClose = () => this.setState({ editModalShow: false });
        const handleDivBlur = event => {
            this.setState({
                Msg: '',
                ShowPartPopUp: false,
                BtnText: "Add Drawing Part",
                UpateId: 0, Part: '', Desc: '', IsUpdate: false
            });
            document.getElementById("CloseModel").click();
        };

        const ClickOnAddPartsBtn = () => {
            this.setState({
                ShowPartPopUp: true
            });

        }
        const handleDivBlurPart = event => {
            this.setState({
                Msg: '',
                ShowPartPopUp: false,
                BtnText: "Add Drawing Part",
                UpateId: 0, Part: '', Desc: '', IsUpdate: false
            });

        };
        const ConfirmationMsg = () => {
            this.setState({
                ShowAlertMsg:"Yes", ShowAlert: false
            });
            handleDivBlur();
        }

        return (
            <>
                <div style={{ width: "100%" }} className={this.state.isActive == true ? 'loading' : ''}>

                    <Table className="mt-4" striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th style={{ width: "5%", display: this.props.disabled ? "none" : "" }}>Action</th>
                                <th style={{ width: "12%" }}>Drawing #</th>
                                <th style={{ width: "3%" }}>Parts</th>
                                <th ></th>
                                {/*<th>File Copy</th>*/}
                                {/* <th>Description</th>*/}

                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Drawings.map((drawing, index) =>

                                <tr key={drawing.dmcDrawingRevDrawingId}>
                                    <td style={{ display: this.props.disabled ? "none" : "" }}>
                                        <ButtonToolbar>
                                            <div style={{ width: "100%", display: "flex" }}>
                                                <div style={{ width: "45%", }}>
                                                    <Button className="mr-1" variant="info" onClick={() => this.handleEdit(true, drawing.dmcDrawingRevDrawingId, drawing.dwgNumber)}

                                                    >
                                                        <FaEdit />
                                                    </Button>

                                                </div>
                                                <div style={{ width: "5px" }}>
                                                    <span > </span>
                                                </div>
                                                <div style={{ width: "45%" }}>
                                                    <Button className="mr-1" variant="danger" onClick={() => this.deleteDrawing(drawing.dmcDrawingRevDrawingId)}>
                                                        <FaTrash />
                                                    </Button>
                                                </div>
                                            </div>
                                        </ButtonToolbar>
                                    </td>
                                    <td>{drawing.dwgNumber}</td>
                                    <td >
                                        {
                                            this.state.DrawingParts.filter(part => {
                                                return part.dmcDrawingRevDrawingId == drawing.dmcDrawingRevDrawingId
                                            }).length > 0 ?
                                                <span style={{ cursor: "pointer" }} onClick={() => this.showParts(drawing.dmcDrawingRevDrawingId)}>{this.state.IsViewpart.indexOf(drawing.dmcDrawingRevDrawingId) > -1 ? <FaMinus /> : <FaPlus />}</span>
                                                : ''
                                        }
                                    </td>
                                    {/*<td>{drawing.fileCopy}</td>*/}
                                    <td>
                                        {
                                            this.state.DrawingParts.filter(part => {
                                                return part.dmcDrawingRevDrawingId == drawing.dmcDrawingRevDrawingId
                                            }).length > 0 ?

                                                <Table className="mt-4" striped bordered hover size="sm" style={{ display: this.state.IsViewpart.indexOf(drawing.dmcDrawingRevDrawingId) > -1 ? 'block' : 'none' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "5%", display: this.props.disabled ? "none" : "" }}>Action</th>
                                                            <th>Part #</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.DrawingParts.filter(part => {
                                                            return part.dmcDrawingRevDrawingId == drawing.dmcDrawingRevDrawingId
                                                        }).map((drawingParts, index) =>
                                                            <tr key={index}>
                                                                <td style={{ display: this.props.disabled ? "none" : "" }}>
                                                                    <ButtonToolbar>
                                                                        <div style={{ width: "100%", display: "flex" }}>

                                                                            <div style={{ width: "50%" }}>
                                                                                <Button className="mr-1" variant="danger" disabled={this.props.disabledVal} onClick={() => this.deleteDrawingPart(drawingParts.dmcDrawingRevDrawingId, drawingParts.dmcDrawingRevRequestDrawingPartsId)}>
                                                                                    <FaTrash />
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </ButtonToolbar>

                                                                </td>
                                                                <td>{drawingParts.part}</td>
                                                                <td>{drawingParts.partDescription}</td>
                                                            </tr>



                                                        )}
                                                    </tbody>
                                                </Table>

                                                : ''


                                        }
                                    </td>

                                </tr>



                            )}
                        </tbody>
                    </Table>


                </div>
                <div className="container">

                    <Modal backdrop="static"
                        {...this.props}
                        size={this.state.ShowAlert == false ? "lg" : "" }
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <div className={this.state.isActive == true ? 'loading' : ''}>
                            <Modal.Header style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }} clooseButton>
                                <div style={{ width: "100%", display: this.state.ShowAlert == false ? "flex" : "none" }}>
                                <div style={{ width: "20%" }}> <Modal.Title id="contained-modal-title-vcenter">
                                    Add Drawing
                                </Modal.Title></div>
                                <div style={{ width: "70%", textAlign: "center" }} >
                                    <span className="Msg" >{this.state.Msg}</span>
                                </div>
                                <div style={{ width: "10%", textAlign: "right" }}><span onClick={handleDivBlur}><FaRegWindowClose style={{ width: "1.5em", height: "1.5em" }} /></span>
                                    <span id="CloseModel" style={{ display: "none" }} onClick={this.props.onHide}></span>
                                </div>
                                </div>
                                <div style={{ width: "100%",display: this.state.ShowAlert == true ? "flex" : "none" }}>
                                <div style={{ width: "20%" }}> <Modal.Title id="contained-modal-title-vcenter">
                                    Warning
                                </Modal.Title></div>
                                <div style={{ width: "70%", textAlign: "center" }} >
                                </div>
                                <div style={{ width: "10%", textAlign: "right" }}>
                                    <FaExclamationTriangle style={{ fontSize: "25px" }} />
                                    </div>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col lg={12} >
                                        <Form ref={this.formRef} onSubmit={this.handleSubmit} id="form1" name="form1" >
                                            <div style={{ display: this.state.ShowAlert == false ? "block" : "none" }}>
                                                <Form.Group controlId="Drawing" style={{ display: "flex", paddingBottom: "15px" }}>
                                                    <Form.Label>Drawing #</Form.Label>
                                                    <Form.Control type="text" name="Drawing" required
                                                        placeholder="Drawing" />
                                                </Form.Group>
                                                {/*<Form.Group controlId="Atex" style={{ display: "flex", paddingBottom: "15px" }}>*/}
                                                {/*    <Form.Label>ATEX</Form.Label>*/}
                                                {/*    <Form.Select as="select" >*/}
                                                {/*        <option value={'No'}  >{'No'}</option>*/}
                                                {/*        <option value={'Yes'} >{'Yes'}</option>*/}
                                                {/*    </Form.Select>*/}
                                                {/*</Form.Group>*/}
                                                {/*<Form.Group controlId="FileCopy" style={{ display: "flex", paddingBottom: "15px" }}>*/}
                                                {/*    <Form.Label >File Copy</Form.Label>*/}
                                                {/*    <Form.Select as="select" >*/}
                                                {/*        <option value={'No'}  >{'No'}</option>*/}
                                                {/*        <option value={'Yes'} >{'Yes'}</option>*/}
                                                {/*    </Form.Select>*/}
                                                {/*    */}{/*<Form.Control type="text" name="FileCopy" */}
                                                {/*    */}{/*placeholder="File Copy"  />*/}
                                                {/*</Form.Group>*/}
                                                <div style={{ width: "100%", textAlign: "right" }} >
                                                    <span style={{
                                                        textDecoration: "underline", fontWeight: "500", cursor: "pointer"
                                                    }}
                                                        onClick={() => ClickOnAddPartsBtn()}>Add Part</span>

                                                </div>
                                                <div style={{
                                                    width: "100%", display:
                                                        this.state.AddParts.length == 0 ?
                                                            "none"
                                                            : "block"
                                                }}>
                                                    <Table className="mt-4" striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "5%" }}>Action</th>
                                                                <th>Part #</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.AddParts.map((Part, index) =>

                                                                <tr key={index}>
                                                                    <td>
                                                                        <ButtonToolbar>
                                                                            <div style={{ width: "100%", display: "flex" }}>
                                                                                <div style={{ width: "45%" }}>
                                                                                    <Button className="mr-1" variant="info" onClick={() => this.UpatePart(Part.id, Part.partNumber, Part.description)}>
                                                                                        <FaEdit />
                                                                                    </Button>

                                                                                </div>
                                                                                <div style={{ width: "5px" }}>
                                                                                    <span > </span>
                                                                                </div>
                                                                                <div style={{ width: "45%" }}>
                                                                                    <Button className="mr-1" variant="danger" onClick={() => this.deletePart(Part.id)}>
                                                                                        <FaTrash />
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </ButtonToolbar>

                                                                    </td>
                                                                    <td>{Part.partNumber}</td>
                                                                    <td>{Part.description}</td>
                                                                </tr>



                                                            )}
                                                        </tbody>
                                                    </Table>



                                                </div>
                                                <Form.Group >
                                                    <div style={{ width: "100%", textAlign: "center" }} >
                                                        <Button  style={{ backgroundColor: "black", color: "white" }} type="submit">
                                                            Add Drawing
                                                        </Button>

                                                    </div>

                                                </Form.Group>
                                            </div>
                                            <div style={{ display: this.state.ShowAlert == true ? "block" : "none" }}>
                                                <p className="alert-div">
                                                    <span className="alert-p">Is This Drawing Related To The Previous Drawing(s)?</span>
                                                    <span className="alert-p">If Not Please Create a New DRR.</span>
                                                </p>

                                                <hr />
                                                <div className="d-flex justify-content-end">
                                                    <span >

                                                                    <Form.Group >
                                                                    <Button style={{ backgroundColor: "black", color: "white", border: 0, borderColor:"black" }}  type="submit">
                                                                               Yes
                                                                            </Button>
                                                                    </Form.Group>
                                                    </span>
                                                    <span className="alert-span"></span>
                                                    <span > <Button style={{ backgroundColor: "black", color: "white", border: 0, borderColor: "black"}} onClick={() => ConfirmationMsg()}>
                                                      No
                                                    </Button>
                                                    </span>
                                                  
                                                </div>
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </div>
                    </Modal>
                    

                    <Modal backdrop="static"
                        {...this.props}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered show={this.state.ShowPartPopUp}
                    >
                        <Modal.Header style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }} clooseButton>
                            <div style={{ width: "20%" }}> <Modal.Title id="contained-modal-title-vcenter">
                                Add Drawing Part
                            </Modal.Title></div>
                            <div style={{ width: "70%", textAlign: "center" }} >
                                <span className="Msg" >{this.state.Msg}</span>
                            </div>
                            <div style={{ width: "10%", textAlign: "right" }}><span onClick={handleDivBlurPart}><FaRegWindowClose style={{ width: "1.5em", height: "1.5em" }} /></span>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col lg={12} >
                                    <Form onSubmit={this.handleSubmitAddPart} >
                                        <Form.Group controlId="Part" style={{ display: "flex", paddingBottom: "15px" }}>
                                            <Form.Label>Part #</Form.Label>
                                            <Form.Control type="text" name="Part"
                                                placeholder="Part" defaultValue={this.state.Part} required />
                                        </Form.Group>
                                        <Form.Group controlId="Description" style={{ display: "flex", paddingBottom: "15px" }}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control type="text" name="Description"
                                                placeholder="Description" as="textarea" defaultValue={this.state.Desc} />
                                        </Form.Group>
                                        <Form.Group >
                                            <div style={{ width: "100%", textAlign: "center" }} >
                                                <Button style={{ backgroundColor: "black", color: "white" }} type="submit">
                                                    {this.state.BtnText}
                                                </Button>
                                            </div>

                                        </Form.Group>

                                    </Form>
                                </Col>
                            </Row>
                        </Modal.Body>

                    </Modal>


                </div>
                <EditDrawing
                    show={this.state.editModalShow}
                    onHide={editModalClose}
                    revID={this.props.revID}
                    autoGenID={this.props.autoGenID}
                    drawingID={drawingID}
                    dwgNumber={dwgNumber}
                    totPartItem={this.state.DrawingParts.length}



                />

            </>
        )
    }

}
