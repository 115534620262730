import React, { Component, useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form, ButtonToolbar } from 'react-bootstrap';
import { FaRegWindowClose, FaEdit, FaTrash } from "react-icons/fa";
import { Table } from 'react-bootstrap';
import '../Dashboard/Dashboard.css';




export class EditDrawing extends Component {
    constructor(props) {
        super(props);
        this.state = { Msg: '', ShowPartPopUp: false, AddParts: [], Part: '', Desc: '', UpdateId: 0, IsUpdate: false, BtnText: "Add Drawing Part", DeleteDrawingIDs: [], isActive: true};
        this.EditDrawinghandleSubmit = this.EditDrawinghandleSubmit.bind(this);
        this.handleSubmitAddPart = this.handleSubmitAddPart.bind(this);
    }
    componentDidUpdate(prevProps) {
     
        if (prevProps.drawingID != this.props.drawingID || prevProps.totPartItem != this.props.totPartItem) {
            this.EventLoad();
        }
    }
    EventLoad()  {
        this.setState({ isActive: true });
        let filterArry = [];
        let count = 0;
        if (this.props.revID > 0) {
            fetch('DashBoard/GetDrawingsParts/' + this.props.drawingID)
                .then(response => response.json())
                .then(data => {
                    for (const str of data) {
                        count = count + 1;
                        filterArry.push({ id: count, drawingID: str.dmcDrawingRevRequestDrawingPartsId, partNumber: str.part, description: str.partDescription })
                    }
                    this.setState({ AddParts: filterArry, isActive: false });
                });
        }
        else if (this.props.autoGenID > 0) {
            fetch('DashBoard/TempGetDrawingsParts/' + this.props.drawingID)
                .then(response => response.json())
                .then(data => {
                    for (const str of data) {
                        count = count + 1;
                        filterArry.push({ id: count, drawingID: str.dmcDrawingRevRequestDrawingPartsId, partNumber: str.part, description: str.partDescription })
                    }
                    this.setState({ AddParts: filterArry, isActive: false });
                });

        }
    }

    handleSubmitAddPart(event) {
        this.setState({isActive: true });
         event.stopPropagation();
        event.preventDefault();
        let filterArry = [];
        if (this.state.IsUpdate == false) {
            let count = this.state.AddParts.length + 1;
            if (this.state.AddParts.length > 0) {
                filterArry = this.state.AddParts;
            }
            filterArry.push({ id: count, drawingID:0, partNumber: event.target.Part.value, description: event.target.Description.value })
        }
        else {
            if (this.state.UpdateId > 0) {
                for (const str of this.state.AddParts) {
                    if (this.state.UpdateId == str.id) {
                        filterArry.push({ id: str.id, drawingID: str.drawingID, partNumber: event.target.Part.value, description: event.target.Description.value })
                    }
                    else {
                        filterArry.push({ id: str.id, drawingID: str.drawingID, partNumber: str.partNumber, description: str.description })
                    }

                }
            }
        }

        this.setState({ AddParts: filterArry, ShowPartPopUp: false, UpateId: 0, Part: '', Desc: '', IsUpdate: false, BtnText: "Add Drawing Part" });
        this.setState({ isActive: false });
    }
    EditDrawinghandleSubmit(event) {
        this.setState({ isActive: true });
        event.stopPropagation();
        if ((this.props.revID > 0 || this.props.autoGenID > 0) && this.props.drawingID > 0) {
            if (this.props.revID > 0) {
                for (const str of this.state.DeleteDrawingIDs) {
                    if (str.drawingID > 0) {
                        fetch('DashBoard/DeleteDrawingPart/' + str.drawingID, {
                            method: 'DELETE',
                            header: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        }).then(res => res.json())
                            .then((result) => {

                            },
                                (error) => {
                                    alert('Failed');
                                })
                    }
                }
            }
            else if (this.props.autoGenID > 0) {
                for (const str of this.state.DeleteDrawingIDs) {
                    if (str.drawingID > 0) {
                        fetch('DashBoard/TempDeleteDrawingPart/' + str.drawingID, {
                            method: 'DELETE',
                            header: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        }).then(res => res.json())
                            .then((result) => {

                            },
                                (error) => {
                                    alert('Failed');
                                })
                    }
                }
            }


            var selectedPartValues = "";
            if (this.state.AddParts.length > 0) {
                for (const str of this.state.AddParts) {
                    if (str.partNumber != "") {
                        selectedPartValues = selectedPartValues + "^" + str.drawingID + "~" + str.partNumber + "~" + str.description;
                    }
                }

            }
            event.preventDefault();

            fetch('DashBoard/AddUpdatedDrawing', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DmcDrawingRevDrawingId: parseInt(this.props.drawingID),
                    DmcDrawingRevId: parseInt(this.props.revID),
                    DwgNumber: "" + event.target.Drawing.value + "",
                    PartsDetails: "" + selectedPartValues + "",
                    AutoGenId: parseInt(this.props.autoGenID)

                })
            })
                .then(res => res.json())
                .then((result) => {
                    localStorage.setItem('UpdateDrawing', "yes")
                    this.setState({
                        Msg: result, isActive: false, ShowPartPopUp: false, UpateId: 0, Part: '', Desc: '', IsUpdate: false, BtnText: "Add Drawing Part", DeleteDrawingIDs: []
                    });
                   
                    setTimeout(() => {
                        this.setState({
                            Msg: ''
                           


                        });
                        document.getElementById("CloseModel").click();
                    }, 2000)
                    this.EventLoad();
                },
                    (error) => {
                        alert('Failed');
                        this.setState({
                            Msg: '', isActive: false,
                            ShowPartPopUp: false, UpateId: 0, Part: '', Desc: '', IsUpdate: false, BtnText: "Add Drawing Part", DeleteDrawingIDs: []

                        });
                    })
        }
        else {
            alert("Something went wrong please close popup and try again")
            this.setState({
                Msg: '',isActive: false,
                ShowPartPopUp: false, UpateId: 0, Part: '', Desc: '', IsUpdate: false, BtnText: "Add Drawing Part", DeleteDrawingIDs: []

            });
        }
    }
    deletePart(id, drawingID) {
        if (window.confirm('Are you sure?')) {

            let filteredSuggestions = []
            filteredSuggestions = this.state.AddParts.filter(part => {
                return part.id != id
            });
            let filterArry = [];
            if (drawingID > 0) {
                if (this.state.DeleteDrawingIDs.length > 0) {
                    filterArry = this.state.DeleteDrawingIDs;
                }
                filterArry.push({drawingID: drawingID})
            }
                
            this.setState({ AddParts: filteredSuggestions, DeleteDrawingIDs: filterArry });
        }
    }
    UpatePart(Id, Part, Desc) {
        this.setState({ Part: Part, UpdateId: Id, Desc: Desc, ShowPartPopUp: true, IsUpdate: true, BtnText: "Update Drawing Part" });
    }

   

    render() {

       
        const handleDivBlur = event => {
            this.setState({
                Msg: '',
                ShowPartPopUp: false,
                BtnText: "Add Drawing Part",
                UpateId: 0, Part: '', Desc: '', IsUpdate: false, DeleteDrawingIDs: []
            });
            document.getElementById("CloseModel").click();
            this.EventLoad();
        };
        const ClickOnAddPartsBtn = () => {
            this.setState({
                ShowPartPopUp: true
            });

        }
        const handleDivBlurPart = event => {
            this.setState({
                Msg: '',
                ShowPartPopUp: false,
                BtnText: "Add Drawing Part",
                UpateId: 0, Part: '', Desc: '', IsUpdate: false, DeleteDrawingIDs: []
            });
          //this.EventLoad();

        };

        return (

            <div className="container" >

                <Modal backdrop="static"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className={this.state.isActive == true ? 'loading' : ''}>
                    <Modal.Header style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }} clooseButton>
                        <div style={{ width: "20%" }}> <Modal.Title id="contained-modal-title-vcenter">
                            Update Drawing
                        </Modal.Title></div>
                        <div style={{ width: "70%", textAlign: "center" }} >
                            <span className="Msg" >{this.state.Msg}</span>
                        </div>
                        <div style={{ width: "10%", textAlign: "right" }}><span onClick={handleDivBlur}><FaRegWindowClose style={{ width: "1.5em", height: "1.5em" }} /></span>
                            <span id="CloseModel" style={{ display: "none" }} onClick={this.props.onHide}></span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={12} >
                                <Form onSubmit={this.EditDrawinghandleSubmit} >
                                    <Form.Group controlId="Drawing" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>Drawing #</Form.Label>
                                        <Form.Control type="text" name="Drawing" required
                                            placeholder="Drawing" defaultValue={this.props.dwgNumber} />
                                    </Form.Group>
                                    {/*<Form.Group controlId="Atex" style={{ display: "flex", paddingBottom: "15px" }}>*/}
                                    {/*    <Form.Label>ATEX</Form.Label>*/}
                                    {/*    <Form.Select as="select" defaultValue={this.props.atex}>*/}
                                    {/*        <option value={'No'}  >{'No'}</option>*/}
                                    {/*        <option value={'Yes'}>{'Yes'}</option>*/}
                                    {/*    </Form.Select>*/}
                                    {/*</Form.Group>*/}
                                    {/*<Form.Group controlId="FileCopy" style={{ display: "flex", paddingBottom: "15px" }}>*/}
                                    {/*    <Form.Label >File Copy</Form.Label>*/}
                                    {/*    <Form.Select as="select" defaultValue={this.props.fileCopy}>*/}
                                    {/*        <option value={'No'}  >{'No'}</option>*/}
                                    {/*        <option value={'Yes'}>{'Yes'}</option>*/}
                                    {/*    </Form.Select>*/}
                                    {/*</Form.Group>*/}
                                    <div style={{ width: "100%", textAlign: "right" }} >
                                        <span style={{
                                            textDecoration: "underline", fontWeight: "500", cursor: "pointer"
                                        }}
                                            onClick={() => ClickOnAddPartsBtn()}>Add Part</span>

                                    </div>
                                    <div style={{
                                        width: "100%", display:
                                            this.state.AddParts.length == 0 ?
                                                "none"
                                                : "block"
                                    }}>
                                        <Table className="mt-4" striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "5%" }}>Action</th>
                                                    <th>Part #</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                                <tbody>
                                            {this.state.AddParts.map((Part, index) =>
                                               
                                                    <tr key={index}>
                                                        <td>
                                                            <ButtonToolbar>
                                                                <div style={{ width: "100%", display: "flex" }}>
                                                                    <div style={{ width: "45%" }}>
                                                                        <Button className="mr-1" variant="info" onClick={() => this.UpatePart(Part.id, Part.partNumber, Part.description)}>
                                                                            <FaEdit />
                                                                        </Button>

                                                                    </div>
                                                                    <div style={{ width: "5px" }}>
                                                                        <span > </span>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}>
                                                                        <Button className="mr-1" variant="danger" onClick={() => this.deletePart(Part.id, Part.drawingID)}>
                                                                            <FaTrash />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </ButtonToolbar>

                                                        </td>
                                                        <td>{Part.partNumber}</td>
                                                        <td>{Part.description}</td>
                                                    </tr>

                                              

                                                    )}
                                                </tbody>
                                        </Table>



                                    </div>
                                   
                                    <Form.Group >
                                        <div style={{ width: "100%", textAlign: "center" }} >
                                            <Button style={{ backgroundColor: "black", color: "white" }} type="submit" >
                                                Update Drawing
                                            </Button>
                                        </div>

                                    </Form.Group>
                                 
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                        </div>
                </Modal>
                <Modal backdrop="static"
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered show={this.state.ShowPartPopUp}
                >
                    <div className={this.state.isActive == true ? 'loading' : ''}>
                    <Modal.Header style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }} clooseButton>
                        <div style={{ width: "20%" }}> <Modal.Title id="contained-modal-title-vcenter">
                            Add Drawing Part
                        </Modal.Title></div>
                        <div style={{ width: "70%", textAlign: "center" }} >
                            <span className="Msg" >{this.state.Msg}</span>
                        </div>
                        <div style={{ width: "10%", textAlign: "right" }}><span onClick={handleDivBlurPart}><FaRegWindowClose style={{ width: "1.5em", height: "1.5em" }} /></span>
                            <span id="CloseModelPart" style={{ display: "none" }} onClick={this.props.onHide}></span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg={12} >
                                <Form onSubmit={this.handleSubmitAddPart} >
                                    <Form.Group controlId="Part" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>Part #</Form.Label>
                                        <Form.Control type="text" name="Part"
                                            placeholder="Part" defaultValue={this.state.Part} required />
                                    </Form.Group>
                                    <Form.Group controlId="Description" style={{ display: "flex", paddingBottom: "15px" }}>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control type="text" name="Description"
                                            placeholder="Description" as="textarea" defaultValue={this.state.Desc} />
                                    </Form.Group>
                                    <Form.Group >
                                        <div style={{ width: "100%", textAlign: "center" }} >
                                            <Button style={{ backgroundColor: "black", color: "white" }} type="submit">
                                                {this.state.BtnText}
                                            </Button>
                                        </div>

                                    </Form.Group>

                                </Form>
                            </Col>
                        </Row>
                        </Modal.Body>
                    </div>

                </Modal>
            </div>
        )
    }

}
